import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import HomePage from "./components/homepage/HomePage";
import AboutConsumer from "./components/about/AboutConsumer";
import BillLekeDekho from "./components/movement/BillLekeDekho";
import EducationSystem from "./components/movement/EducationSystem";
import FoodAdult from "./components/movement/FoodAdult";
import NationalInte from "./components/movement/NationalInte";
import Committee from "./components/committee/Committee";
import AboutCro from "./components/about/AboutCro";
import CHomePage from "./components/complaintComponent/CHomePage";
import NoPage from "./NoPage";
import './App.css'

import AdminLogin from "./components/complaintComponent/admin/AdminLogin";
import AdminPanel from "./components/complaintComponent/admin/SuperAdminDashboard";
import SuperAdminDashboardView from "./components/complaintComponent/admin/SuperAdminDashboardView";
import PendingComplaints from "./components/complaintComponent/admin/complaints/PendingComplaints";
import AcceptedComplaints from "./components/complaintComponent/admin/complaints/AcceptedComplaints";
import DeclinedComplaints from "./components/complaintComponent/admin/complaints/DeclinedComplaints";
import PaidComplaints from "./components/complaintComponent/admin/complaints/PaidComplaints";
import UnpaidComplaints from "./components/complaintComponent/admin/complaints/UnpaidComplaints";
import ResolvedComplaints from "./components/complaintComponent/admin/complaints/ResolvedComplaints";

import MembershipUser from "./components/complaintComponent/admin/usersSection/MembershipUser";
import NonMembershipUser from "./components/complaintComponent/admin/usersSection/NonMembershipUser";
import Contact_details from "./components/complaintComponent/admin/contact/Contact-details";

import UserDashboard from "./components/complaintComponent/user/UserDashboard";
import PendingComplaintsUser from "./components/complaintComponent/user/complaints/PendingComplaintsUser";
import AcceptedComplaintsUser from "./components/complaintComponent/user/complaints/AcceptedComplaintsUser";
import DeclinedComplaintsUser from "./components/complaintComponent/user/complaints/DeclinedComplaintsUser";
import PaidComplaintsUser from "./components/complaintComponent/user/complaints/PaidComplaintsUser";
import ResolvedComplaintsUser from "./components/complaintComponent/user/complaints/ResolvedComplaintsUser";
import UnpaidComplaintsUser from "./components/complaintComponent/user/complaints/UnpaidComplaintsUser";
import UserSettings from "./components/complaintComponent/user/UserSettings";

import About from "./components/complaintComponent/about";
import ContactUs from "./components/complaintComponent/contactUs";
import RefundPolicy from "./components/complaintComponent/refundPolicy";
import Privacy from "./components/complaintComponent/privacyPolicy";
import TermCondition from "./components/complaintComponent/term&condition";
import Complaints from "./components/complaintComponent/complaints";
import Register from "./components/complaintComponent/user/Register";
import UserLogin from "./components/complaintComponent/user/UserLogin";
import UserLoginComplain from "./components/complaintComponent/user/UserLoginComplain";
import Faq from "./components/complaintComponent/faq";
import Membership from "./components/complaintComponent/membership";
import AllComplaints from "./components/complaintComponent/admin/complaints/AllComplaints";
import ForgotPassword from "./components/complaintComponent/user/ForgotPassword";
import ForgotPasswordSendEmail from "./components/complaintComponent/user/ForgotPasswordSendEmail";
import CancellationPolicy from "./components/complaintComponent/cancellationPolicy.js";

import Down from './Down.js'
import Starter from "./components/Starter.js";
import Form1 from "./components/Registration/Form1.jsx";
import EventReg from "./components/complaintComponent/admin/Event/EventReg.js";
import PaymentsNew from "./components/complaintComponent/admin/payments/PaymentsNew.js";
import SuccessPage from "./components/complaintComponent/user/payments/Success.js";
import FailedPage from "./components/complaintComponent/user/payments/Failled.js";
import FailedTransaction from "./components/complaintComponent/admin/payments/FailedTransaction.js";
import AllTransaction from "./components/complaintComponent/admin/payments/AllTransactions.js";
import GetMembership from "./components/complaintComponent/user/GetMembership.js";

const App = () => {
  return (

    // All routes

    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Down />} /> */}
        <Route path="/" element={<Starter />} />
        <Route path="/organisation" element={<HomePage />} />
        <Route path="/about/aboutcro" element={<AboutCro />} />
        <Route path="/about/aboutconsumer" element={<AboutConsumer />} />
        <Route path="/movement/billlekedekho" element={<BillLekeDekho />} />
        <Route path="/movement/educationSys" element={<EducationSystem />} />
        <Route path="/movement/foodAdult" element={<FoodAdult />} />
        <Route path="/movement/nationalInte" element={<NationalInte />} />
        <Route path="/committee" element={<Committee />} />


        {/* <Route path="/organisation/reg/" element={<Form1 />} /> */}

        <Route path="/complaint" element={<CHomePage />} />
        <Route path="*" element={<NoPage />} />
        {/* Admin Routes */}
        <Route path="/complaint/adminLogin" element={<AdminLogin />} />
        <Route path="/complaint/adminPanel" element={<AdminPanel />}>
          <Route path="superAdminDashboardView" element={<SuperAdminDashboardView />} />
          <Route path="pendingComplaints" element={<PendingComplaints />} />
          <Route path="acceptedComplaints" element={<AcceptedComplaints />} />
          <Route path="declinedComplaints" element={<DeclinedComplaints />} />
          <Route path="paidComplaints" element={<PaidComplaints />} />
          <Route path="unpaidComplaints" element={<UnpaidComplaints />} />
          <Route path="resolvedComplaints" element={<ResolvedComplaints />} />
          <Route path="all-Complaints" element={<AllComplaints />} />
          <Route path="membershipUser" element={<MembershipUser />} />
          <Route path="nonMembershipUser" element={<NonMembershipUser />} />
          <Route path="contact-details" element={<Contact_details />} />
          {/* <Route path="event-reg" element={<EventReg />} /> */}
          <Route path="payments/success" element={<PaymentsNew />} />
          <Route path="payments/failed" element={<FailedTransaction />} />
          <Route path="payments/all" element={<AllTransaction />} />
        </Route>

        <Route path="/payment/success" element={<SuccessPage />} />
        <Route path="/payment/fail" element={<FailedPage />} />

        <Route path="/complaint/userDashboard" element={<UserDashboard />}>
          <Route path="pendingComplaintsUser" element={<PendingComplaintsUser />} />
          <Route path="acceptedComplaintsUser" element={<AcceptedComplaintsUser />} />
          <Route path="declinedComplaintsUser" element={<DeclinedComplaintsUser />} />
          {/* <Route path="paidComplaintsUser" element={<PaidComplaintsUser />} /> */}
          <Route path="resolvedComplaintsUser" element={<ResolvedComplaintsUser />} />
          {/* <Route path="unpaidComplaintsUser" element={<UnpaidComplaintsUser />} /> */}
          <Route path="userSettings" element={<UserSettings />} />
        </Route>

        <Route path="/complaint/about" element={<About />} />
        <Route path="/complaint/contact" element={<ContactUs />} />
        <Route path="/complaint/refund-policy" element={<RefundPolicy />} />
        <Route path="/complaint/cancellation-policy" element={<CancellationPolicy />} />
        <Route path="/complaint/privacy-policy" element={<Privacy />} />
        <Route path="/complaint/terms-condition" element={<TermCondition />} />
        <Route path="/complaint/file-complaint" element={<Complaints />} />
        <Route path="/complaint/AdminPanel" element={<AdminPanel />} />
        <Route path="/complaint/register" element={<Register />} />
        <Route path="/complaint/userLogin" element={<UserLogin />} />
        <Route path="/complaint/get-membership" element={<GetMembership />} />
        <Route path="/complaint/forgotPassword/:token" element={<ForgotPassword />} />
        <Route path="/complaint/forgotPasswordSendEmail" element={<ForgotPasswordSendEmail />} />
        <Route path="/complaint/userLoginComplain" element={<UserLoginComplain />} />
        <Route path="/complaint/faq" element={<Faq />} />
        <Route path="/complaint/membership" element={<Membership />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
