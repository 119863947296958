import { gql } from "@apollo/client";

export const SUPER_ADMIN_LOGIN = gql`
  mutation SignInSuperAdmin($signInSuperAdminInput: signInSuperAdminInput) {
    signInSuperAdmin(SignInSuperAdminInput: $signInSuperAdminInput) {
      superAdminId
      superAdminToken
    }
  }
`;

export const CREATE_RZ_ORDER = gql`
  mutation CreateRZOrder($orderInput: OrderInput!) {
    createRZOrder(orderInput: $orderInput) {
      id
      orderId
      entity
      amount
      amount_paid
      amount_due
      currency
      receipt
      offer_id
      status
      attempts
      notes
      created_at
    }
  }
`;

export const UPDATE_USER_PAYMENTS = gql`
  mutation UpdateUserPayments(
    $updateUserPaymentsInput: updateUserPaymentsInput!
  ) {
    updateUserPayments(updateUserPaymentsInput: $updateUserPaymentsInput) {
      _id
      contact
      email
      createdDateTime
      status
      payments {
        id
        amount
        currency
        receipt
        amount_paid
        amount_due
        offer_id
        status
        attempts
        notes
        created_at
        orderId
      }
    }
  }
`;

export const CREATE_RZ_CHECKOUT = gql`
  mutation CreateCheckOut($checkOutInput: CheckOutInput!) {
    createCheckOut(checkOutInput: $checkOutInput) {
      id
      orderId
      email
      razorpay_order_id
      razorpay_payment_id
      razorpay_signature
      created_at
    }
  }
`;

export const USER_REGISTER = gql`
  mutation Mutation($userInput: userInput) {
    createUser(UserInput: $userInput) {
      _id
    }
  }
`;

export const CREATE_RAZORPAY_ORDER = gql`
  mutation CreateOrder($amount: Int!, $currency: String!, $receipt: String!) {
    createOrder(amount: $amount, currency: $currency, receipt: $receipt) {
      id
      amount
      currency
      receipt
      amount_due
      amount_paid
      attempts
      created_at
      notes
      offer_id
      status
    }
  }
`;

export const USER_LOGIN = gql`
  mutation SignInUser($signInUserInput: signInUserInput) {
    signInUser(SignInUserInput: $signInUserInput) {
      userId
      userToken
      isPremium
    }
  }
`;

export const CREATE_COMPLAINT = gql`
  mutation Mutation($complaintInput: complaintInput) {
    createComplaint(ComplaintInput: $complaintInput) {
      id
    }
  }
`;

export const COMPLAINT_STATUS_CHANGE = gql`
  mutation Mutation($complaintId: ID, $statusName: String) {
    changeComplaintStatus(complaintId: $complaintId, statusName: $statusName) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation Mutation($userUpdateInput: userUpdateInput) {
    updateUser(UserUpdateInput: $userUpdateInput) {
      id
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation Mutation($userId: ID, $password: String) {
    updateUserPassword(userId: $userId, password: $password) {
      _id
    }
  }
`;

export const UPDATE_COMPLAINT_REASON = gql`
  mutation UpdateComplaintReason($complaintId: ID, $reason: String) {
    updateComplaintReason(complaintId: $complaintId, reason: $reason) {
      id
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation Mutation($contactInput: contactInput) {
    createContact(ContactInput: $contactInput) {
      id
      name
      email
      phone
      msg
    }
  }
`;

export const SEND_MAIL_CONTACT = gql`
  mutation Mutation($email: String) {
    sendMailForContact(email: $email)
  }
`;

export const SEND_MAIL_MEMBERSHIP = gql`
  mutation Mutation($email: String) {
    sendMailForMembership(email: $email)
  }
`;

export const SEND_MAIL_COMPLAINTS = gql`
  mutation Mutation($email: String) {
    sendMailForComplaints(email: $email)
  }
`;
export const SEND_RESET_LINK = gql`
  mutation Mutation($email: String) {
    sendResetLink(email: $email)
  }
`;
export const UPDATE_PASSWORD_RESET = gql`
  mutation Mutation($updatePasswordReset: updatePasswordReset) {
    updatePasswordReset(UpdatePasswordReset: $updatePasswordReset) {
      id
    }
  }
`;

export const MUTATION_EMAIL = gql`
  mutation Mutation($email: String, $htmlContext: String) {
    createMail(email: $email, htmlContext: $htmlContext)
  }
`;

export const MUTATION_USER_DELETE = gql`
  mutation Mutation($userId: ID) {
    deleteUser(userId: $userId) {
      id
    }
  }
`;

export const MUTATION_WDC_REG = gql`
  mutation Mutation($wcdRegistrationInput: WCDRegistrationInput) {
    createWCDRegistration(WCDRegistrationInput: $wcdRegistrationInput) {
      id
    }
  }
`;
