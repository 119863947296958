import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Table, Row, Col, Button } from "react-bootstrap";
import { GET_WDC_REG } from "../../../../graphql/Query";
import { FaAngleLeft, FaAngleRight, FaDownload } from "react-icons/fa6";

function EventReg() {
  const { loading, error, data } = useQuery(GET_WDC_REG);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // Filter function
  const filteredData = data.getWCDRegistration.filter((registration) => {
    return (
      registration.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      registration.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      registration.phone.includes(searchTerm) ||
      registration.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
      registration.state.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  // Pagination Logic
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const serachVal = (e)=>{
    setSearchTerm(e);
    setCurrentPage(1);
  }

  return (
    <div>
      <Row className="mb-3 p-0">
        <Col md={9}>&nbsp;</Col>
        <Col md={3}>
          <input
            className="float-right shadow border rounded-2"
            type="text"
            placeholder="Search by Full Name, Email, Phone, City, or State"
            value={searchTerm}
            style={{ width: '100%' }}
            onChange={(e) => serachVal(e.target.value)}
          />
        </Col>
      </Row>

      <Table bordered responsive style={{ fontSize: 12 }}>
        <thead>
          <tr>
            <th>S.NO.</th>
            <th>Full Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>City</th>
            <th>State</th>
            <th>Img</th>
          </tr>
        </thead>
        <tbody>
          {currentRecords.map((r, index) => (
            <tr key={index}>
              <td>{indexOfFirstRecord + index + 1}</td>
              <td>{r.fullName}</td>
              <td>{r.email}</td>
              <td>{r.phone}</td>
              <td>{r.city}</td>
              <td>{r.state}</td>
              <td>
              <div
                className="mt-3 position-relative"
                style={{ height: 100, width: 100 }}
              >
                <img
                  src={r.photo ? `https://cro-org-bucket.s3.ap-south-1.amazonaws.com/${r.photo}` : 'https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg'}
                  alt=""
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
                <div
                  style={{
                    height: 25,
                    width: 25,
                    background: "white",
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer",
                  }}
                  className="d-flex justify-content-center align-items-center"
                  onClick={() =>
                    window.open(
                      `https://cro-org-bucket.s3.ap-south-1.amazonaws.com/${r && r.photo}`,
                      "_blank"
                    )
                  }
                >
                  <FaDownload />
                </div>
              </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-center">
        <Button
          variant="primary"
          className="me-2"
          style={{ padding: '10px 15px' }}
          size="sm"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <FaAngleLeft />
        </Button>
        <Button
          variant="primary"
          className="me-2"
          style={{ padding: '10px 20px' }}
          size="sm"
          disabled
        >
          {currentPage}
        </Button>
        <Button
          className="me-2"
          variant="primary"
          onClick={() => setCurrentPage(currentPage + 1)}
          size="sm"
          style={{ padding: '10px 15px' }}
          disabled={indexOfLastRecord >= filteredData.length}
        >
          <FaAngleRight />
        </Button>
      </div>
    </div>
  );
}

export default EventReg;
