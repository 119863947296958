import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Table, Row, Col, Pagination, Badge } from "react-bootstrap";
import Moment from "react-moment";
import {
  GET_ALL_CHECKOUT,
  GET_PAYMENT_DETAILS,
  GET_USER_BY_EMAIL,
} from "../../../../graphql/Query";
import { BsEye } from "react-icons/bs";
import UserDetailModal from "./UserDetailModal";

function PaymentsNew() {
  const { data: checkoutData } = useQuery(GET_ALL_CHECKOUT);
  console.log(checkoutData);
  const [filterValue, setFilterValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;

  const [fetchPaymentDetails, { data: paymentData }] =
    useLazyQuery(GET_PAYMENT_DETAILS);

  useEffect(() => {
    if (checkoutData) {
      checkoutData.getAllCheckout.forEach((checkout) => {
        fetchPaymentDetails({
          variables: {
            paymentId: checkout.razorpay_payment_id,
          },
        });
      });
    }
  }, [checkoutData, fetchPaymentDetails]);

  // Function to convert Unix timestamp to "DD MM YYYY" format
  const convertUnixToDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    return <Moment format="DD MMM YYYY">{date}</Moment>;
  };

  // Filter function
  const filteredData =
    checkoutData &&
    checkoutData.getAllCheckout.filter((checkout) => {
      return (
        checkout.razorpay_payment_id.includes(filterValue) ||
        checkout.razorpay_order_id.includes(filterValue) ||
        checkout.email.includes(filterValue)
      );
    });

  // Pagination Logic
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredData?.length / recordsPerPage); i++) {
    pageNumbers.push(i);
  }

  // Function to handle page navigation
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Display records for the current page
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords =
    filteredData && filteredData.slice(indexOfFirstRecord, indexOfLastRecord);

  const SerachVal = (val) => {
    setFilterValue(val);
    setCurrentPage(1);
  };

  // TODO  function to fetch user details by email

  const [getUserByEmail] = useLazyQuery(GET_USER_BY_EMAIL);

  const [showUserDetail, setShowUserDetail] = useState(false);

  const [userDetail, setUserDetails] = useState({
    fullName: "",
    contact: "",
    email: "",
    status: "",
  });

  const handleUserDetail = (email) => {
    getUserByEmail({
      variables: {
        email: email,
      },
    }).then((res) => {
      setUserDetails(res.data.getUserByEmail);
      setShowUserDetail(true);
    });
  };

  return (
    <div>
      <UserDetailModal
        show={showUserDetail}
        setShow={setShowUserDetail}
        Data={userDetail}
      />
      <Row>
        <Col md={12}>
          <input
            className="shadow border mb-3"
            type="text"
            placeholder="Search by Payment ID, Order ID, Email, or Date"
            value={filterValue}
            onChange={(e) => SerachVal(e.target.value)}
            style={{ float: "right" }}
          />
        </Col>
      </Row>

      <Table bordered responsive style={{ fontSize: 12 }}>
        <thead>
          <tr>
            <th>S.NO.</th>
            <th>Payment Id</th>
            <th>Order Id</th>
            <th>Email</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Date</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {currentRecords &&
            currentRecords.map((checkout, index) => (
              <tr key={checkout.id}>
                <td>{index + 1}</td>
                <td>{checkout.razorpay_payment_id}</td>
                <td>{checkout.razorpay_order_id}</td>
                <td>{checkout.email}</td>
                <td>
                  {checkout && checkout.orderId
                    ? checkout.orderId.amount / 100
                    : ""}{" "}
                  {checkout.orderId && checkout.orderId.currency}
                </td>
                <td>
                  {checkout.orderId && (
                    <>
                      {checkout.orderId.status === "failed" && (
                        <Badge bg="danger" className="badge badge-danger">
                          Failed
                        </Badge>
                      )}

                      {checkout.orderId.status === "success" && (
                        <Badge bg="success" className="badge badge-success">
                          Success
                        </Badge>
                      )}

                      {(checkout.orderId.status === "" ||
                        checkout.orderId.status === null ||
                        checkout.orderId.status === undefined) && (
                        <Badge bg="danger" className="badge badge-danger">
                          Failed
                        </Badge>
                      )}
                    </>
                  )}
                </td>
                <td>
                  {convertUnixToDate(
                    parseInt(checkout.orderId && checkout.orderId.created_at)
                  )}
                </td>
                {/* TODO button to view */}
                <td>
                  <BsEye
                    style={{
                      background: "#C1C7CC",
                      minWidth: "2rem",
                      minHeight: "2rem",
                      padding: "0.5rem",
                      borderRadius: "0.4rem",
                      font: "black",
                    }}
                    onClick={() => handleUserDetail(checkout.email)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      <div className="pagination-container">
        <Pagination>
          {pageNumbers.map((number) => (
            <Pagination.Item
              key={number}
              active={number === currentPage}
              onClick={() => handlePageChange(number)}
            >
              {number}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>
    </div>
  );
}

export default PaymentsNew;
