import React, { useState, useEffect } from 'react';
import Navbar from '../navbar';
import { Container, Row, Col, Card, Form, Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_PASSWORD_RESET } from '../../../graphql/Mutation';
import { GET_TOKEN } from '../../../graphql/Query';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import '../../../assets/css/userlogin.css';

export default function ForgotPassword({ props }) {

    const alert = useAlert();
    const navigate = useNavigate()
    let { token } = useParams();

    const { data: tokenData, loading: tokenLoading } = useQuery(GET_TOKEN, {
        variables: {
            "tokenId": `${token}`
        }
    });

    const [validated, setValidated] = useState(false);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [updatePasswordReset, { loading }] = useMutation(UPDATE_PASSWORD_RESET, {
        onError: (e) => {
            alert.error('Somthing error, please try again!!!');
        },
    });

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
        } else {
            setValidated(false);
            event.preventDefault();
            updatePasswordReset({
                variables: {
                    "updatePasswordReset": {
                        "userId": `${tokenData && tokenData.checkToken.userId}`,
                        "tokenId": `${tokenData && tokenData.checkToken.id}`,
                        "password": `${password}`
                    }
                },
            }).then(() => {
                setPassword('');
                setConfirmPassword('');
                alert.success('Password reset successfully!!!');
                navigate('/complaint/userLogin')
            });
        }
    };

    return (
        <>
            <Navbar />
            {
                tokenLoading ?
                    <Spinner animation="border" role="status" className="mx-auto d-block" style={{ marginTop: 200 }}>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    :
                    tokenData && tokenData.checkToken === null ?
                        <>
                            <h1 style={{ marginTop: 200, fontFamily: 'poppins', fontSize: 30, fontWeight: 'bold', textAlign: 'center' }}>Token Expired!!!</h1>
                            <Link to="/complaint/ForgotPasswordSendEmail" style={{ marginTop: 200, fontFamily: 'poppins', fontSize: 15, textAlign: 'center' }}><h1 style={{ fontFamily: 'poppins', fontSize: 15, textAlign: 'center', color: '#3498db' }}>Try Again</h1></Link>
                        </>
                        :
                        <div className="loginContainer">
                            <Container style={{ marginTop: 50, padding: 100, marginBottom: '100%' }} fluid>
                                <Row>
                                    <Col md={4}></Col>
                                    <Col md={4}>
                                        <Card style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                                            <h1 style={{ fontFamily: 'poppins', fontSize: 25, textAlign: 'center', marginTop: 20 }}>RESET PASSWORD</h1>
                                            <h5 style={{ fontFamily: 'poppins', fontSize: 12, textAlign: 'center', marginTop: 10, color: '#7f8c8d' }}>Reset password for {tokenData && tokenData.checkToken.email}</h5>
                                            <Card.Body>
                                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                            <Form.Label style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>Enter New Password</Form.Label>
                                                            <Form.Control style={{ fontFamily: 'poppins', fontSize: 12 }} required type="password" placeholder="Enter New Password" onChange={e => setPassword(e.target.value)} value={password} />
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                            <Form.Label style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>Enter Confirm Password</Form.Label>
                                                            <Form.Control style={{ fontFamily: 'poppins', fontSize: 12 }} required type="password" placeholder="Enter Confirm Password" onChange={e => setConfirmPassword(e.target.value)} value={confirmPassword} />
                                                        </Form.Group>
                                                    </Row>
                                                    {
                                                        loading ?
                                                            <Spinner animation="border" role="status" className="mx-auto d-block">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </Spinner>
                                                            :
                                                            <Button type="submit" className="mx-auto d-block loginBtnn" style={{ width: '80%' }}>Reset</Button>
                                                    }
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={4}></Col>
                                </Row>
                            </Container>
                        </div>
            }
        </>

    )
}
