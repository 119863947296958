import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import slider1 from "../../assets/sliderNew1.jpg";
import slider2 from "../../assets/sliderNew2.jpg";
import slider3 from "../../assets/sliderNew3.jpg";
import slider4 from "../../assets/sliderNew4.jpg";
import slider5 from "../../assets/sliderNew5.jpg";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
// import "swiper/css/effect-creative";
import "swiper/css/effect-fade";

// import required modules
import {  Autoplay, EffectFade } from "swiper/modules";

const Hero = () => {
  const [activeSlide, setActiveSlide] = useState(1);

  const swiperRef = useRef(null);

  const IncreaseSlide = () => {
    if (activeSlide < 4 && swiperRef.current) {
      swiperRef.current.slideTo(activeSlide);
      setActiveSlide((prevSlide) => prevSlide + 1);
    }
  };

  const DecreaseSlide = () => {
    if (activeSlide > 1 && swiperRef.current) {
      swiperRef.current.slideTo(activeSlide - 2);
      setActiveSlide((prevSlide) => prevSlide - 1);
    }
  };
  return (
    <>
    <br />
    <br />
    <br />
    <br />
      <div className="container-fluid mt-5">
        {/* <div
          id="carouselExampleAutoplaying"
          className={`carousel slide ${styles.carousel}`}
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className={`carousel-item active ${styles.image}`}>
              <img
                src={slider1}
                className="d-block w-100 pt-5 mt-4"
                alt="alt"
              />
            </div>
            <div className={`carousel-item ${styles.image}`}>
              <img
                src={slider2}
                className="d-block w-100 pt-5 mt-4"
                alt="alt"
              />
            </div>
            <div className={`carousel-item ${styles.image}`}>
              <img
                src={slider3}
                className="d-block w-100 pt-5 mt-4"
                alt="alt"
              />
            </div>
            <div className={`carousel-item ${styles.image}`}>
              <img
                src={slider4}
                className="d-block w-100 pt-5 mt-4"
                alt="alt"
              />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div> */}
        <div
          className={` rounded-3 position-relative overflow-hidden  ${styles.hero_main}`}
        >
          <Swiper
            ref={swiperRef}
            grabCursor={false}
            effect={"fade"}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            fadeEffect={{
              prev: {
                shadow: true,
                translate: ["-20%", 0, -1],
              },
              next: {
                translate: ["100%", 0, 0],
              },
            }}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            modules={[EffectFade, Autoplay]}
            className="mySwiper3"
            style={{ pointerEvents: "none" }}
          >
            <SwiperSlide>
              <div className={`row m-0 p-0 ${styles.images}`}>
                <img src={slider1} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={`row m-0 p-0 ${styles.images}`}>
                <img src={slider2} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={`row m-0 p-0 ${styles.images}`}>
                <img src={slider3} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={`row m-0 p-0 ${styles.images}`}>
                <img src={slider4} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={`row m-0 p-0 ${styles.images}`}>
                <img src={slider5} alt="" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className={`row mt-4 ${styles.hero}`}>
          <div className="col d-flex align-items-center justify-content-center ">
            <div
              className={`d-flex align-items-center justify-content-center gap-3 ${styles.btns_main}`}
            >
              <div
                className={`d-flex align-items-center justify-content-center ${styles.left_btn}`}
                onClick={DecreaseSlide}
              >
                <FaArrowLeft className={`${styles.arrows}`} />
              </div>
              <div
                className={`d-flex align-items-center justify-content-center ${styles.right_btn}`}
                onClick={IncreaseSlide}
              >
                <FaArrowRight className={`${styles.arrows}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
