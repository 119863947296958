import React, { useState, useEffect } from 'react';
import Navbar from '../navbar';
import { Container, Row, Col, Card, Form, Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { SEND_RESET_LINK } from '../../../graphql/Mutation';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAlert } from 'react-alert';
import '../../../assets/css/userlogin.css';

export default function ForgotPasswordSendEmail() {

    const alert = useAlert();

    const navigate = useNavigate();
    const location = useLocation();

    const [validated, setValidated] = useState(false);

    const [email, setEmail] = useState('');

    const [sendResetLink, { loading }] = useMutation(SEND_RESET_LINK, {
        onCompleted: ({ signInUser }) => {
            alert.success('Reset link sent to your email, please check your email and reset your password');
        },
        onError: (e) => {
            alert.error('Email not exist!!!');
        },
    });

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
        } else {
            setValidated(false);
            event.preventDefault();
            sendResetLink({
                variables: {
                    "email": `${email}`
                },
            }).then(() => {
                setEmail('');
            });
        }
    };

    useEffect(() => {
        if (localStorage.getItem('userToken')) {
            navigate('/complaint');
        }
    }, [navigate]);

    return (
        <div className="loginContainer">
            <Navbar />
            <Container style={{ marginTop: 80, padding: 100, marginBottom: '100%' }} fluid>
                <Row>
                    <Col md={4}></Col>
                    <Col md={4}>
                        <Card style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                            <h1 style={{ fontFamily: 'poppins', fontSize: 25, textAlign: 'center', marginTop: 20 }}>SEND RESET LINK</h1>
                            <Card.Body>
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                                            <Form.Label style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>Enter Email Address</Form.Label>
                                            <Form.Control style={{ fontFamily: 'poppins', fontSize: 12 }} required type="email" placeholder="Enter Email Address" onChange={e => setEmail(e.target.value)} value={email} />
                                        </Form.Group>
                                    </Row>
                                    {
                                        loading ?
                                            <Spinner animation="border" role="status" className="mx-auto d-block">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                            :
                                            <Button type="submit" className="mx-auto d-block loginBtnn" style={{ width: '80%' }}>Send Link</Button>
                                    }
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}></Col>
                </Row>
            </Container>
        </div>
    )
}
