import React, { useState } from "react";
import { Container, Row, Col, Table, Button, Modal } from "react-bootstrap";
import { FaEye } from "react-icons/fa";

import { GET_PENDING_COMPALINT_BY_USERID } from "../../../../graphql/Query";
import { useQuery } from "@apollo/client";
import Moment from "react-moment";
import { FaDownload } from "react-icons/fa6";

export default function PendingComplaintsUser() {
  const userId = localStorage.getItem("userId");

  const { data } = useQuery(GET_PENDING_COMPALINT_BY_USERID, {
    variables: {
      userId: `${userId}`,
    },
    pollInterval: 300,
  });

  console.log("data", data);

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedComplaint, setSelectedComplaint] = useState({});

  const clickShowDetailsModal = (cId) => {
    const selectedComplaint = data.getPendingComplaintByUserId.find(
      (Cdata) => Cdata.id === cId
    );
    setSelectedComplaint(selectedComplaint);
    setShowDetailsModal(true);
  };
  console.log("cm :", selectedComplaint);

  const imgSRC = `https://hariom-bucket.s3.ap-south-1.amazonaws.com/${selectedComplaint.image}`;

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h1
              style={{
                fontFamily: "poppins",
                fontSize: 20,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Pending Complaints
            </h1>
            <Table bordered style={{ fontFamily: "poppins", fontSize: 12 }}>
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Complaint Id</th>
                  <th>Date/Time</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  <td>1</td>
                  <td>1234567890</td>
                  <td>22-07-2023, 11:13 AM</td>
                  <td>Real-Estate</td>
                  <td>Accepted</td>
                  <td><Button size="sm" variant="success"><FaEye /></Button></td>
                  <td><Button size="sm" variant="primary"><FaExchangeAlt /></Button></td>
                </tr> */}

                {data &&
                  data.getPendingComplaintByUserId
                    .slice()
                    .reverse()
                    .map((Cdata, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{Cdata.complaintId}</td>
                        <td>
                          <Moment format="DD-MM-YYYY, hh:mm A">
                            {Cdata.createdDateTime}
                          </Moment>
                        </td>
                        <td>{Cdata.complaintType}</td>
                        <td>{Cdata.status}</td>
                        <td>
                          <Button
                            size="sm"
                            variant="success"
                            onClick={() => clickShowDetailsModal(Cdata.id)}
                          >
                            <FaEye />
                          </Button>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>

      {/* modal for show the details of the complaint */}

      <Modal
        show={showDetailsModal}
        onHide={() => setShowDetailsModal(false)}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-0 m-0">
          {/* <Col md={12} className="p-0 m-0">
            <Row md={12}> */}
              <h1
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontFamily: "poppins",
                  fontSize: 25,
                }}
              >
                Complaint Details
              </h1>
              <Table
                bordered
                style={{ fontFamily: "poppins", fontSize: 12 }}
                responsive
              >
                <tbody>
                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Details of opposite party
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>
                      Company/Firm/Service Provider Name
                    </td>
                    <td>{selectedComplaint.yourProviderName}</td>
                    <td style={{ fontWeight: "bold" }}>Email</td>
                    <td>{selectedComplaint.yourEmail}</td>
                    <td style={{ fontWeight: "bold" }}>Contact</td>
                    <td>{selectedComplaint.yourContact}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>
                      Name of concern person
                    </td>
                    <td>{selectedComplaint.concernPerson}</td>
                    <td style={{ fontWeight: "bold" }}>Name of product</td>
                    <td>{selectedComplaint.productName}</td>
                    <td style={{ fontWeight: "bold" }}>Address</td>
                    <td>{selectedComplaint.yourFullAddress}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>City</td>
                    <td>{selectedComplaint.yourCity}</td>
                    <td style={{ fontWeight: "bold" }}>State</td>
                    <td>{selectedComplaint.yourState}</td>
                    <td style={{ fontWeight: "bold" }}>Pincode</td>
                    <td>{selectedComplaint.yourPincode}</td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      {" "}
                      Have You Complained To Anyother Platform Earlier
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>
                      Have You Complained To Anyother Platform Earlier
                    </td>
                    <td>{selectedComplaint.haveYouComplaintToAnother}</td>
                    <td style={{ fontWeight: "bold" }}>Name of Platform</td>
                    <td colspan={3}>
                      {selectedComplaint.haveYouComplaintToAnotherText}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Resolution
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Resolution</td>
                    {selectedComplaint.resolution ? (
                      <td>
                        {selectedComplaint &&
                          selectedComplaint.resolution.map((d) => (
                            <h6 style={{ fontFamily: "poppins" }}>{d},</h6>
                          ))}
                      </td>
                    ) : (
                      ""
                    )}
                    <td style={{ fontWeight: "bold" }}>Name of Platform</td>
                    <td colspan={3}>
                      {selectedComplaint.haveYouComplaintToAnotherText}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Product/Service Name
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>
                      {selectedComplaint.haveStartCourtActionText}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Product Or Service Value (INR)
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>₹{selectedComplaint.productValue}</td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      How Would You Like Your Complaint To Get Solved?
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>
                      ₹{selectedComplaint.howYouLikeComplaintSolve}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      When Did This Transaction / Problem Occur?
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Date</td>
                    <td>{selectedComplaint.whenProblemOccurDate}</td>
                    <td style={{ fontWeight: "bold" }}>Time</td>
                    <td colspan={3}>
                      {selectedComplaint.whenProblemOccurTime}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Select A Type Which Is Most Relevant To Your Complaint
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>{selectedComplaint.complaintType}</td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Please Describe Your Complaint In Detail
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>
                      {selectedComplaint.complaintDescription}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Attechments
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9} className="d-flex">
                      {selectedComplaint && selectedComplaint.image &&
                        selectedComplaint.image.length > 0 &&
                        selectedComplaint.image.map((r) => (
                          <div
                            className="mt-3 position-relative ms-3"
                            style={{ height: 100, width: 100 }}
                          >
                            <img
                              src={
                                r
                                  ? `https://cro-org-bucket.s3.ap-south-1.amazonaws.com/${r}`
                                  : "https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg"
                              }
                              alt=""
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                              }}
                            />
                            <div
                              style={{
                                height: 25,
                                width: 25,
                                background: "white",
                                position: "absolute",
                                right: 0,
                                top: 0,
                                cursor: "pointer",
                              }}
                              className="d-flex justify-content-center align-items-center"
                              onClick={() =>
                                window.open(
                                  `https://cro-org-bucket.s3.ap-south-1.amazonaws.com/${
                                    r ? r : ""
                                  }`,
                                  "_blank"
                                )
                              }
                            >
                              <FaDownload />
                            </div>
                          </div>
                        ))}
                    </td>
                  </tr>

                </tbody>
              </Table>
            {/* </Row>
          </Col> */}
        </Modal.Body>
      </Modal>
    </>
  );
}
