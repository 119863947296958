import React from 'react'
// import '../assets/js/theme'

import { Container, Row, Col, Accordion } from 'react-bootstrap'



const Questions = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        {/* <h6 style={{ fontFamily: 'poppins', fontSize: 25, fontWeight: 'bold', textAlign: 'center', color: '#4e4039' }}>Frequently asked questions</h6> */}
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><span style={{ fontFamily: 'poppins', fontSize: 17, fontWeight: 'bold', color: '#4e4039' }}>Who Can file a Complaint through this portal?</span></Accordion.Header>
                                <Accordion.Body style={{ fontFamily: 'poppins', fontSize: 15 }}>
                                    Any citizen of India can file a complaint after becoming a member of the organization.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                                <Accordion.Header><span style={{ fontFamily: 'poppins', fontSize: 17, fontWeight: 'bold', color: '#4e4039' }}>When can a consumer file his complaint?</span></Accordion.Header>
                                <Accordion.Body style={{ fontFamily: 'poppins', fontSize: 15 }}>
                                    When a customer is unsatisfied by the service of any service provider or seller, when defects are detected in concerned products or service or when a customer is cheated by a seller then a complaint can be registered.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2">
                                <Accordion.Header><span style={{ fontFamily: 'poppins', fontSize: 17, fontWeight: 'bold', color: '#4e4039' }}>What is the charge of membership fees?</span></Accordion.Header>
                                <Accordion.Body style={{ fontFamily: 'poppins', fontSize: 15 }}>
                                    The annual membership fees of consumer rights organisation is Rs 1000 (In words one thousand rupees only).
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3">
                                <Accordion.Header><span style={{ fontFamily: 'poppins', fontSize: 17, fontWeight: 'bold', color: '#4e4039' }}>How many complaints can be filed by a member?</span></Accordion.Header>
                                <Accordion.Body style={{ fontFamily: 'poppins', fontSize: 15 }}>
                                    A member can lodge any number of complaints in a year, but complaints should be named with his/her identity as per our membership record.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="4">
                                <Accordion.Header><span style={{ fontFamily: 'poppins', fontSize: 17, fontWeight: 'bold', color: '#4e4039' }}>How do I Register my Complaint?</span></Accordion.Header>
                                <Accordion.Body style={{ fontFamily: 'poppins', fontSize: 15 }}>
                                    After registering as a member, You need to share your details mentioned in the complaint form and upload the required documents for the assessment of the complaint. Our System will accept the case and you can check the status of the complaint with login details.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="5">
                                <Accordion.Header><span style={{ fontFamily: 'poppins', fontSize: 17, fontWeight: 'bold', color: '#4e4039' }}>How will I know the status of my case?</span></Accordion.Header>
                                <Accordion.Body style={{ fontFamily: 'poppins', fontSize: 15 }}>
                                    A login is provided to you at the time of registration which shall have the status of case movement in your dashboard. We also provide regular updates through email.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="6">
                                <Accordion.Header><span style={{ fontFamily: 'poppins', fontSize: 17, fontWeight: 'bold', color: '#4e4039' }}>How much time does it take to resolve my complaint?</span></Accordion.Header>
                                <Accordion.Body style={{ fontFamily: 'poppins', fontSize: 15 }}>
                                    Depending upon the merit of the case, usually it takes 30-45 days to resolve. However, if we require the case to move to consumer commission it may take 4-6 months depending on the availability of dates for hearing on the case.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="7">
                                <Accordion.Header><span style={{ fontFamily: 'poppins', fontSize: 17, fontWeight: 'bold', color: '#4e4039' }}>How do I make sure that my complaint has been Registered?</span></Accordion.Header>
                                <Accordion.Body style={{ fontFamily: 'poppins', fontSize: 15 }}>
                                    After registering your complaint you will get a consent mail on your provided mail id within 48 (working) hours.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="8">
                                <Accordion.Header><span style={{ fontFamily: 'poppins', fontSize: 17, fontWeight: 'bold', color: '#4e4039' }}>Where can I file my consumer complaint?</span></Accordion.Header>
                                <Accordion.Body style={{ fontFamily: 'poppins', fontSize: 15 }}>
                                    You can drop your complaint through our dedicated complaint portal available at www.thecro.org
                                </Accordion.Body>
                            </Accordion.Item>

                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Questions