import React from "react";
import Navbar from "../complaintComponent/navbar";
import Home from "../complaintComponent/home";
import ThreeSteps from "../complaintComponent/threeSteps";
import Sectors from "../complaintComponent/sectors";
import HowWeWork from "../complaintComponent/howWeWork";
import Services from "../complaintComponent/services";
import Count from "../complaintComponent/count";
import Testimonials from "../complaintComponent/testimonials";
import Footer from "../complaintComponent/footer";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from "../../assets/img/logo.png";

const CHomePage = () => {
  return (
    <>
      <main className="main container-fluid">
        {window.scroll(0, 0)}
        <Navbar />
        <Home />
        <ThreeSteps />
        <Sectors />
        <HowWeWork />
        <Services />
        <Count />
        <Testimonials />
        <Footer />
        <FloatingWhatsApp
          phoneNumber="919977252252"
          accountName="CRO"
          allowClickAway
          notification
          notificationDelay={60000}
          avatar={logo}
        />
      </main>
    </>
  );
};

export default CHomePage;
