import React, { useEffect } from 'react'
import '../../assets/css/theme.css'
import '../../assets/css/newstyle.css'

import { Image } from 'react-bootstrap';
import Trust from '../../assets/img/icons/trust.png'
import Advocacy from '../../assets/img/icons/advocacy.png'
import Solution from '../../assets/img/icons/solution.png'
import Safe from '../../assets/img/icons/safe.png'

const Services = () => {

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <>
            <section id="services" className="services section-bg">
                <div className="container">
                    <div className="section-title" data-aos="fade-up">
                        <p style={{ fontFamily: 'poppins' }}>Why We</p>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon-box">
                                <Image src={Trust} style={{ width: 100, height: 100 }} />
                                <h4 className="title fs-1 text-primary">Trust</h4>
                                <p className="description">Consumer often faces difficulty in asserting their rights. <br />Lack of
                                    Transparency and accountability has impacted our customers confidence</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="200">
                            <div className="icon-box">
                                <Image src={Advocacy} style={{ width: 100, height: 100 }} />
                                <h4 className="title fs-1 text-primary">Advocacy</h4>
                                <p className="description">
                                    Empower the customers to assert their rights with ease and transparency.
                                    <br />
                                    We are committed to promoting transparency, accountability and fairness to the marketplace
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="300">
                            <div className="icon-box">
                                <Image src={Solution} style={{ width: 100, height: 100 }} />
                                <h4 className="title fs-1 text-primary">One Stop Solution</h4>
                                <p className="description">
                                    One stop solution for all consumer complaints
                                    <br />
                                    Simple and intuitive platform which connects consumers with resources that they need to assert their
                                    rights and get results
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="400">
                            <div className="icon-box">
                                <Image src={Safe} style={{ width: 100, height: 100 }} />
                                <h4 className="title fs-1 text-primary">Secure and Safe</h4>
                                <p className="description">
                                    Advanced data analytics for proactive support
                                    <br />
                                    Easy filing of complaints, Expert support real time tracking, and a database of result cases
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Services