import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { GET_USER_BY_ID } from '../../../graphql/Query'
import { UPDATE_USER } from '../../../graphql/Mutation'
import { useQuery, useMutation } from '@apollo/client'
import { useAlert } from 'react-alert';

let stateArray = ["Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jammu and Kashmir", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttarakhand", "Uttar Pradesh", "West Bengal", "Andaman and Nicobar Islands", "Chandigarh", "Dadra and Nagar Haveli", "Daman and Diu", "Delhi", "Lakshadweep", "Puducherry"];


export default function EditUser() {
    const alert = useAlert();
    const userId = localStorage.getItem('userId')

    const { data: userData } = useQuery(GET_USER_BY_ID, {
        variables: {
            "userId": `${userId}`
        }
    })

    useEffect(() => {
        if (userData) {
            setName(userData.getUserById.fullName);
            setfatherName(userData.getUserById.fatherName);
            setDob(userData.getUserById.dob);
            setoccupation(userData.getUserById.occupation);
            setContact(userData.getUserById.contact);
            setEmail(userData.getUserById.email);
            setbloodGroup(userData.getUserById.bloodGroup);
            setAddressLine1(userData.getUserById.addressLine1);
            setAddressLine2(userData.getUserById.addressLine2);
            setCity(userData.getUserById.city);
            setState(userData.getUserById.state);
            setPincode(userData.getUserById.pincode);
        }
    }, [userData])

    const [validated, setValidated] = useState(false);

    console.log(userData)
    const [name, setName] = useState('')
    const [fatherName, setfatherName] = useState("")
    const [dob, setDob] = useState('')
    const [occupation, setoccupation] = useState('')
    const [contact, setContact] = useState('')
    const [email, setEmail] = useState('')
    const [bloodGroup, setbloodGroup] = useState('')
    const [addressLine1, setAddressLine1] = useState('')
    const [addressLine2, setAddressLine2] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [pincode, setPincode] = useState('')

    const [updateUser] = useMutation(UPDATE_USER, {
        refetchQueries: [
            GET_USER_BY_ID
        ]
    })

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setValidated(true);
            event.preventDefault();
            updateUser({
                variables: {
                    "userUpdateInput": {
                        "userId": `${userId}`,
                        "fullName": `${name}`,
                        "fatherName": `${fatherName}`,
                        "dob": `${dob}`,
                        "occupation": `${occupation}`,
                        "contact": `${contact}`,
                        "email": `${email}`,
                        "bloodGroup": `${bloodGroup}`,
                        "addressLine1": `${addressLine1}`,
                        "addressLine2": `${addressLine2}`,
                        "city": `${city}`,
                        "state": `${state}`,
                        "pincode": `${pincode}`,
                    }
                }
            }).then(() => {
                alert.success('User Updated');
            })
        }

    };

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>Full Name</Form.Label>
                        <Form.Control style={{ fontFamily: 'poppins', fontSize: 12 }} required type="text" placeholder="Full Name" onChange={e => setName(e.target.value)} value={name} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>Fathers Name</Form.Label>
                        <Form.Control style={{ fontFamily: 'poppins', fontSize: 12 }} required type="text" placeholder="Fathers Name" onChange={e => setfatherName(e.target.value)} value={fatherName} />
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                        <Form.Label style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>Occupation</Form.Label>
                        <Form.Control style={{ fontFamily: 'poppins', fontSize: 12 }} required type="text" placeholder="Occupation" onChange={e => setoccupation(e.target.value)} value={occupation} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                        <Form.Label style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>Mobile No.</Form.Label>
                        <Form.Control style={{ fontFamily: 'poppins', fontSize: 12 }} required type="number" placeholder="Contact" onChange={e => setContact(e.target.value)} value={contact} onWheel={event => event.currentTarget.blur()} />
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                        <Form.Label style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>Email ID</Form.Label>
                        <Form.Control style={{ fontFamily: 'poppins', fontSize: 12 }} required type="email" placeholder="Email" onChange={e => setEmail(e.target.value)} value={email} />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                        <Form.Label style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>Blood group</Form.Label>
                        <Form.Control style={{ fontFamily: 'poppins', fontSize: 12 }} required type="text" placeholder="Blood group" onChange={e => setbloodGroup(e.target.value)} value={bloodGroup} />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                        <Form.Label style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>DOB</Form.Label>
                        <Form.Control style={{ fontFamily: 'poppins', fontSize: 12 }} required type="date" onChange={e => setDob(e.target.value)} value={dob} />
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} md="12" className='mb-3' controlId="validationCustom02">
                        <Form.Label style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>Address</Form.Label>
                        <Form.Control style={{ fontFamily: 'poppins', fontSize: 12 }} required as="textarea" placeholder="Address line 1..." onChange={e => setAddressLine1(e.target.value)} value={addressLine1} />
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                        <Form.Control style={{ fontFamily: 'poppins', fontSize: 12 }} required as="textarea" placeholder="Address line 2..." onChange={e => setAddressLine2(e.target.value)} value={addressLine2} />
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                        <Form.Label style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>City</Form.Label>
                        <Form.Control style={{ fontFamily: 'poppins', fontSize: 12 }} required type="text" placeholder="City" onChange={e => setCity(e.target.value)} value={city} />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                        <Form.Label style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>State</Form.Label>
                        <Form.Control required as="select" style={{ fontFamily: 'poppins', fontSize: 12 }} onChange={e => setState(e.target.value)} value={state}>
                            <option value="" selected="selected" disabled="disabled">Choose State</option>
                            {
                                stateArray.map(stateData =>
                                    <option value={stateData}>{stateData}</option>
                                )
                            }
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                        <Form.Label style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>Pincode</Form.Label>
                        <Form.Control style={{ fontFamily: 'poppins', fontSize: 12 }} required type="number" placeholder="Pincode" onChange={e => setPincode(e.target.value)} value={pincode} onWheel={event => event.currentTarget.blur()} />
                    </Form.Group>
                </Row>

                <Button type="submit" className="mx-auto d-block" style={{ background: "#FF5F3F", border: "none", marginTop: 20 }}>Update</Button>
            </Form>
        </>

    )
}
