import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Outlet, Link } from "react-router-dom";
import { FaChevronDown, FaBars } from "react-icons/fa";
import {
  AiOutlineLogout,
  AiOutlineHome,
} from "react-icons/ai";
import { MdOutlineNoteAlt } from "react-icons/md";
import { BsGear } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import "../../../assets/css/componentcss/SuperAdminDashboard.css";
import "../../../assets/css/componentcss/AdminDashboard.css";

export default function UserDashboard() {
  const navigate = useNavigate();

  useEffect(() => {
    var dropdown = document.querySelectorAll(".dropdown");
    var flag = 1;
    dropdown.forEach((item) => {
      item.addEventListener("click", function () {
        if (flag === 1) {
          item.classList.add("active");
          flag = 0;
        } else {
          item.classList.remove("active");
          flag = 1;
        }
      });
    });
  }, []);

  useEffect(() => {
    var sidebar = document.querySelector(".sidebar");
    var flag2 = 0;
    const menu = document.querySelector(".bx-menu");
    menu.addEventListener("click", function () {
      if (flag2 == 0) {
        sidebar.classList.add("close");
        flag2 = 1;
      } else {
        sidebar.classList.remove("close");
        flag2 = 0;
      }
    });
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("userToken")) {
      navigate("/complaint/userLogin");
    }
  }, [navigate]);

  function logoutHandel() {
    localStorage.removeItem("userToken");
    localStorage.removeItem("userId");
    navigate("/complaint");
  }

  return (
    <>
      <div className={"sidebar"} style={{ overflow: "hidden" }}>
        <Link
          to="/complaint"
          className="logo-box"
          style={{ textDecoration: "none" }}
        >
          <h6
            style={{
              color: "#fff",
              fontFamily: "poppins",
              fontWeight: "bold",
              fontSize: 25,
              marginTop: 30,
              textAlign: "center",
            }}
          >
            User Dashboard
          </h6>
        </Link>
        <ul className="sidebar-list">
          <li>
            <div className="title">
              <Link
                to="/complaint"
                className="link"
                style={{ textDecoration: "none" }}
              >
                <i className="bx bx-grid-alt">
                  <AiOutlineHome />
                </i>
                <span className="name">Home</span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/complaint"
                className="link submenu-title"
                style={{ textDecoration: "none" }}
              >
                Home
              </Link>
            </div>
          </li>

          <li className="dropdown" style={{ overflow: "hidden" }}>
            <div className="title">
              <Link className="link" style={{ textDecoration: "none" }}>
                <i className="bx bx-book-alt">
                  <MdOutlineNoteAlt />
                </i>
                <span className="name">Complaints</span>
              </Link>
              <i className="bx bxs-chevron-down">
                <FaChevronDown />
              </i>
            </div>
            <div className="submenu">
              <Link
                to="/complaint/userDashboard/pendingComplaintsUser"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Pending complaints
              </Link>
              <Link
                to="/complaint/userDashboard/acceptedComplaintsUser"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Accepted complaints
              </Link>
              <Link
                to="/complaint/userDashboard/declinedComplaintsUser"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Declined complaints
              </Link>
              {/* <Link
                to="/complaint/userDashboard/paidComplaintsUser"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Paid complaints
              </Link>
              <Link
                to="/complaint/userDashboard/unpaidComplaintsUser"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Unpaid complaints
              </Link> */}
              <Link
                to="/complaint/userDashboard/resolvedComplaintsUser"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Resolved complaints
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link
                to="/complaint/userDashboard/userSettings"
                className="link"
                style={{ textDecoration: "none" }}
              >
                <i className="bx bx-grid-alt">
                  <BsGear />
                </i>
                <span className="name">Settings</span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/complaint/userDashboard/userSettings"
                className="link submenu-title"
                style={{ textDecoration: "none" }}
              >
                Settings
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link
                to="/complaint"
                className="link"
                style={{ textDecoration: "none" }}
                onClick={() => logoutHandel()}
              >
                <i className="bx bx-grid-alt">
                  <AiOutlineLogout />
                </i>
                <span className="name">Log out</span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/complaint"
                className="link submenu-title"
                style={{ textDecoration: "none" }}
                onClick={() => logoutHandel()}
              >
                Log out
              </Link>
            </div>
          </li>
        </ul>
      </div>
      <section className="home">
        <div className="toggle-sidebar">
          <FaBars
            className={"bx bx-menu"}
            style={{
              width: 30,
              height: 30,
              marginLeft: 10,
              marginTop: "-100px",
              color: "#FD7D72",
            }}
          />
        </div>
        <Container style={{ padding: "20px" }} fluid>
          <Row>
            <Col md={12}>
              <Outlet />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
