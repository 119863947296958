import React, { useEffect } from 'react'
import Navbar from './navbar'
import Footer from './footer'

const CancellationPolicy = () => {

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <>
            <Navbar />
            <div className="container mt-10">
                <h1 className='text-center mb-5' style={{ fontWeight: "800" }}>Cancellation Policy</h1>
                <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>Please note that once we start working on your complaint, we are unable to offer any refund to you. We charge money for membership of the organisation and covering our expenses for contacting, drafting, preparing and sending documents & notices to you and the company. Sometimes inspite of our best efforts the company may still refuse to resolve the problem. In such cases, we deem that we have provided our services satisfactorily and have done everything possible to resolve the matter.
                </p>
                <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>As we offer basic services free and only charge money to cover expenses of providing providing annual membership, so we are unable to offer any refund on your membership fees. Being member of the organisation we provide id card and complaint assistance services on your complaint. We do not under any circumstances issue refunds for services. This is non-refundable since it is not returnable. Please check the description of the service properly, before buying it and paying for it. We offer money back guarantee within 7 days time of making payment. But we have no obligation to provide you a refund in following situations like:
                </p>
                <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>- If your membership is registered within 15- 30 days of payment/ subscription.
                </p>
                <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>You do not want it after you have purchased & paid;
                </p>
                <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>- Your complaint got resolved after publishing;
                </p>
                <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>- The service did not meet your expectations;
                </p>
                <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>- You simply change your mind;
                </p>
                <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>- You purchased any service by mistake;
                </p>
                <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>- We have already started working on your complaint;
                </p>
                <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>- We have already assigned and expert assistance for your support.
                </p>
                <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>- You have received any service like posting at social media, sending email or phone call from us etc; or
                </p>
                <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>- You do not have sufficient time & knowledge to use the service or the help provided.
                </p>
                <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>In order to claim money back, please contact us and provide these below details to receive refund:
                </p>
                <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>- Your Membership login ID.
                </p>
                <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>- Your email id or mobile number used while making payment
                </p>
                <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>- Your transaction number/ payment receipt.
                </p>
                <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>Please note it may take upto 30 days in getting refund from us.
                </p>
            </div>
            <Footer />
        </>
    )
}

export default CancellationPolicy