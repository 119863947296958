import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../../assets/css/componentcss/SuperAdminDashboardView.css";
import { Bar, Pie } from "react-chartjs-2";
import "chart.js/auto";

import {
  GET_USER,
  GET_ALL_COMPLAINTS,
  GET_ALL_RESOLVED_COMPLAINTS,
  GET_ALL_PENDING_COMPLAINTS,
  GET_ACCEPTED_COMPALINT,
} from "../../../graphql/Query";
import { useQuery } from "@apollo/client";

export default function SuperAdminDashboardView() {
  const { data: getAllUser } = useQuery(GET_USER, {
    pollInterval: 300,
  });
  const { data: getAllComplaint } = useQuery(GET_ALL_COMPLAINTS, {
    pollInterval: 300,
  });
  const { data: getAllResolvedComplaint } = useQuery(
    GET_ALL_RESOLVED_COMPLAINTS,
    {
      pollInterval: 300,
    }
  );
  const { data: getAllPedingComplaint } = useQuery(GET_ALL_PENDING_COMPLAINTS, {
    pollInterval: 300,
  });

  const UserData = [
    {
      id: 1,
      name: "Total Complaints",
      userGain: 100,
    },
    {
      id: 2,
      name: "Pending Complaints",
      userGain: 200,
    },
    {
      id: 3,
      name: "Resolved Complaints",
      userGain: 300,
    },
    {
      id: 4,
      name: "Accepted Complaints",
      userGain: 400,
    },
  ];

  const [userDataa, setUserData] = useState({
    labels: UserData.map((data) => data.name),
    datasets: [
      {
        label: "Users Gained",
        data: [
          getAllComplaint && getAllComplaint.getAllComplaint.length,
          getAllPedingComplaint &&
            getAllPedingComplaint.getAllPendingComplaint.length,
          getAllResolvedComplaint &&
            getAllResolvedComplaint.getAllResolvedComplaint.length,
          getAllUser && getAllUser.getAllUsers.length,
        ],
        backgroundColor: ["#2ecc71", "#3498db", "#e67e22", "#9b59b6"],
        borderColor: "black",
        borderWidth: 0,
      },
    ],
  });

  useEffect(() => {
    setUserData({
      labels: UserData.map((data) => data.name),
      datasets: [
        {
          label: "Users Gained",
          data: [
            getAllComplaint && getAllComplaint.getAllComplaint.length,

            getAllPedingComplaint &&
              getAllPedingComplaint.getAllPendingComplaint.length,

            getAllResolvedComplaint &&
              getAllResolvedComplaint.getAllResolvedComplaint.length,

            data && data.getAcceptedComplaint.length,
          ],
          backgroundColor: ["#2ecc71", "#3498db", "#e67e22", "#9b59b6"],
          borderColor: "black",
          borderWidth: 0,
        },
      ],
    });
  }, [
    getAllComplaint,
    getAllPedingComplaint,
    getAllResolvedComplaint,
    getAllUser,
  ]);

  const [options, setOptions] = useState({
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "All Details",
      },
      style: {
        width: 100,
        height: 100,
      },
    },
  });

  const { data } = useQuery(GET_ACCEPTED_COMPALINT, {
    pollInterval: 300,
  });

  return (
    <>
      <div className="main">
        <div className="cardBox">
          <div className="card">
            <div>
              <div className="numbers">
                {getAllComplaint && getAllComplaint.getAllComplaint.length}
              </div>
              <div className="cardName">Total Complaints</div>
            </div>
            <div className="iconBx"></div>
          </div>
          <div className="card">
            <div>
              <div className="numbers">
                {getAllPedingComplaint &&
                  getAllPedingComplaint.getAllPendingComplaint.length}
              </div>
              <div className="cardName">Pending Complaints</div>
            </div>
            <div className="iconBx"></div>
          </div>
          <div className="card">
            <div>
              <div className="numbers">
                {getAllResolvedComplaint &&
                  getAllResolvedComplaint.getAllResolvedComplaint.length}
              </div>
              <div className="cardName">Resolved Complaints</div>
            </div>
            <div className="iconBx"></div>
          </div>
          <div className="card">
            <div>
              <div className="numbers">
                {data && data.getAcceptedComplaint.length}
              </div>
              <div className="cardName">Accepted Complaints</div>
            </div>
            <div className="iconBx"></div>
          </div>
        </div>
        <Row>
          <Col md={6}>
            <Bar data={userDataa} />
          </Col>
          <Col md={6}>
            <Pie data={userDataa} style={{ width: "280px", height: "280px" }} />
          </Col>
        </Row>
      </div>
    </>
  );
}
