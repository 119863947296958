import React, { useLayoutEffect } from "react";
import Logo from "../assets/logo.png";
import bg from "../assets/starter/background.jpg";
import laptop_man from "../assets/starter/laptop_man.png";
import people from "../assets/starter/people_holding_hand.png";
import leftimg from "../assets/starter/woman_left2.png";
import rightimg from "../assets/starter/man_right2.png";
import leftbtn from "../assets/starter/click_here_box.png";
import righttbtn from "../assets/starter/click_here_box2.png";
import { useNavigate } from "react-router-dom";
import gsap from "gsap";

const Starter = () => {
  const navigate = useNavigate();

  useLayoutEffect(() => {
    gsap.fromTo(
      ".left_img",
      { x: -100, opacity: 0 },
      { x: 0, opacity: 1, duration: 1, delay: 0.5 }
    );
    gsap.fromTo(
      ".right_img",
      { x: 100, opacity: 0 },
      { x: 0, opacity: 1, duration: 1, delay: 0.5 }
    );
    gsap.fromTo(
      ".left_btn",
      { y: 100, opacity: 0 },
      { y: 0, opacity: 1, duration: 1, delay: 1 }
    );
    gsap.fromTo(
      ".right_btn",
      { y: 100, opacity: 0 },
      { y: 0, opacity: 1, duration: 1, delay: 1 }
    );

    gsap.fromTo(
      ".logo",
      { y: -100, opacity: 0 },
      { y: 0, opacity: 1, duration: 1, delay: 1.5 }
    );
  }, []);
  return (
    <>
      <div className="starter py-5">
        <div className="bg_img">
          <img src={bg} alt="" />
        </div>

        <div className="row h-100">
          <div className="col-md-4 col1_starter h-100 position-relative">
            <img src={leftimg} alt="" className="left_img" />
            <img
              src={leftbtn}
              alt=""
              className="left_btn"
              onClick={() => navigate("/organisation")}
            />
          </div>
          <div className="col-md-4 col2_starter d-flex flex-column align-items-center">
            <img src={Logo} alt="" className="logo" />
            <h4 className="fw-semibold mt-4">उपभोक्ता अधिकार संगठन</h4>
            <h5 className="fw-semibold text-center">
              CRO - Consumer Rights Organisation
            </h5>
            <div className="imgpart_middle">
              <img
                src={laptop_man}
                alt=""
                className="laptop_man d-block mx-auto"
              />
              <div className="rounded_bar">
                <img src={people} alt="" />
              </div>
            </div>
          </div>
          <div className="col-md-4 col3_starter position-relative h-100">
            <img src={rightimg} alt="" className="right_img" />
            <img
              src={righttbtn}
              alt=""
              className="right_btn"
              onClick={() => navigate("/complaint")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Starter;
