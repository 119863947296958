import React, { useEffect } from 'react'
import Questions from './questions'
import Footer from './footer'
import Navbar from './navbar'

const Faq = () => {

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <>
            <Navbar />
            <div className="faq">
                <div className="top" style={{ height: "40vh", width: "100%", background: "#ff9151", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <h2 style={{ color: "#fff", fontWeight: "bold", fontFamily: 'poppins' }}>Frequently asked questions !</h2>
                </div>
                <div className="bottom container-fluid" style={{ marginTop: "-80px" }}>
                    <Questions />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Faq