import React, { useEffect, useState } from 'react';
import ani from '../../assets/img/ani.gif';
import { Container } from 'react-bootstrap'

const WoWeAre = () => {

    const [showAllParagraphs, setShowAllParagraphs] = useState(false);

    useEffect(() => {
        const woWeAre = document.querySelector(".woWeAre");
        const btn = document.querySelector(".learn-more");

        if (showAllParagraphs) {
            woWeAre.style.maxHeight = "fit-content";
            btn.innerHTML = "Less";
        } else {
            woWeAre.style.maxHeight = "48vh";
            btn.innerHTML = "Learn more";
        }
    }, [showAllParagraphs]);

    const toggleParagraphs = () => {
        setShowAllParagraphs((prevShowAll) => !prevShowAll);
    };

    return (
        <>
            <Container fluid style={{ marginTop: 30 }}>
                <div>
                    <div className="row flex-center align-items-md-center">
                        <div className="col-md-6 col-lg-4 text-center mb-6 mb-md-0">
                            <img className="img-fluid shadow-lg" src={ani} alt="..." style={{ borderRadius: "3rem" }} />
                        </div>
                        <div className="col-md-6 text-center text-md-start mb-6 offset-0 woWeAre" style={{ marginTop: 60, maxHeight: "48vh", overflow: "hidden", transition: "all .5s ease"}}>
                            <h6 className="fs-0 text-uppercase fw-bold text-primary" style={{ fontFamily: 'poppins' }}>Who we are ?</h6>
                            <h6 className="fw-bold fs-2 fs-lg-3 lh-sm" style={{ fontFamily: 'poppins' }}>Welcome to India’s leading Consumer Complaint Cell (CCC)</h6>
                            <p className="my-4 fs-1 pe-xl-8" style={{ fontFamily: 'poppins' }}>CCC is an online consumer complaint platform in India that helps consumers
                                resolve their complaints against companies quickly and effectively. The platform allows consumers to file
                                complaints against companies and provides a hassle-free resolution process that includes escalation of the
                                complaint to higher authorities, legal notice drafting, and even filing a consumer court case if required
                            </p>
                            {showAllParagraphs && (
                                <>
                                    <p className="my-4 fs-1 pe-xl-8" style={{ fontFamily: 'poppins' }}>
                                        CCC's platform is designed to empower consumers by providing them with a voice and a means to seek justice for their grievances. The platform is user-friendly and easy to use, with a simple interface that allows consumers to file complaints quickly and easily. Once a complaint is filed, the platform's team of experts works with the consumer and the company to resolve the issue in a timely and efficient manner.
                                    </p>
                                    <p className="my-4 fs-1 pe-xl-8" style={{ fontFamily: 'poppins' }}>
                                        CCC has helped thousands of consumers resolve their complaints against companies across various industries, including telecom, e-commerce, banking, insurance, and more. The platform is committed to ensuring that consumers' rights are protected and that they receive the justice they deserve.
                                    </p>
                                    <p className="my-4 fs-1 pe-xl-8" style={{ fontFamily: 'poppins' }}>
                                        <span style={{ fontWeight: 'bold', color: '#34495e' }}>OUR VISION:</span> A new India in which every consumer keeps thorough knowledge of his rights and their correct uses.
                                    </p>
                                    <p className="my-4 fs-1 pe-xl-8" style={{ fontFamily: 'poppins' }}>
                                        <span style={{ fontWeight: 'bold', color: '#34495e' }}>OUR MISSION:</span> To organize, educate and make consumers aware of their rights, make best effort to stop unfair trade practices and solve consumer's problem/complaints
                                    </p>
                                </>
                            )}
                            <div className="text-center" style={{ position: "absolute", zIndex: "999", bottom: "0%", left: "47%", transform: "translateX(-50%)" }}>
                                <a className="btn hover-top btn-collab learn-more" role="button" style={{ fontFamily: 'poppins'}} onClick={toggleParagraphs}>
                                    {showAllParagraphs ? "Less" : "Learn more"}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default WoWeAre;
