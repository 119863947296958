import React, { useEffect, useState } from 'react'
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import '../../assets/css/count.css'



const Count = () => {

    gsap.registerPlugin(ScrollTrigger);
    const [num1, setNum1] = useState(0)
    const [num2, setNum2] = useState(0)
    const [num3, setNum3] = useState(0)
    const [num4, setNum4] = useState(0)

    useEffect(() => {

        const updateContent = () => {
            setNum1((prevNum1) => {
                const newNum1 = prevNum1 + 1;
                return newNum1 > 27 ? 27 : newNum1;
            });

            setNum2((prevNum2) => {
                const newNum2 = prevNum2 + 4;
                return newNum2 > 1000 ? 1000 : newNum2;
            });

            setNum3((prevNum3) => {
                const newNum3 = prevNum3 + 8;
                return newNum3 > 3000 ? 3000 : newNum3;
            });

            setNum4((prevNum4) => {
                const newNum4 = prevNum4 + 4;
                return newNum4 > 1000 ? 1000 : newNum4;
            });
        }

        gsap.to('.num1', {
            scrollTrigger: {
                trigger: 'body',
                start: 'top -220%',
            },
            onUpdate: updateContent,
            ease: 'Expo.ease',
            duration: 2.5,
        });
        gsap.to('.num2', {
            scrollTrigger: {
                trigger: 'body',
                start: 'top -220%',
            },
            onUpdate: updateContent,
            ease: 'Expo.ease',
            duration: 2.5,
        });

        gsap.to('.num3', {
            scrollTrigger: {
                trigger: 'body',
                start: 'top -220%',
            },
            onUpdate: updateContent,
            ease: 'Expo.ease',
            duration: 2.5,
        });

        gsap.to('.num4', {
            scrollTrigger: {
                trigger: 'body',
                start: 'top -220%',
            },
            onUpdate: updateContent,
            ease: 'Expo.ease',
            duration: 2.5,
        });

    }, []);

    return (
        <>
            <section className="py-6 bg-soft-warning ">
                <div className="container">
                    <div className="row d-flex">
                        <div className="col col1">
                            <h1><span className="num1">{num1}</span>+</h1>
                            <div className="line"></div>
                            <h2 style={{ fontFamily: 'poppins' }}>STATES</h2>
                        </div>
                        <div className="col col2">
                            <h1><span className="num2">{num2}</span>+</h1>
                            <div className="line"></div>
                            <h2 style={{ fontFamily: 'poppins' }}>COMPLAINTS</h2>
                        </div>
                        <div className="col col3">
                            <h1><span className="num3">{num3}</span>+</h1>
                            <div className="line"></div>
                            <h2 style={{ fontFamily: 'poppins' }}>MEMBERS</h2>
                        </div>
                        <div className="col col4">
                            <h1><span className="num4">{num4}</span>+</h1>
                            <div className="line"></div>
                            <h2 style={{ fontFamily: 'poppins' }}>ACTIVITIES</h2>
                        </div>

                    </div>
                </div>

            </section>
        </>
    )
}

export default Count