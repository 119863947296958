import React from 'react'
import testimonial from '../../assets/img/user.png'
import quot from "../../assets/img/icons/quot.svg";

const Testimonials = () => {
    return (
        <>
            <section>

                <div className="container">
                    <div className="row">
                        <div className="section-title" data-aos="fade-up">
                            <p>Testimonials</p>
                        </div>
                        <div className="col-lg-6 mb-6">
                            <div className="d-flex flex-column flex-sm-row align-items-start"><img
                                className="me-sm-3 my-2 my-sm-0 align-self-center align-self-sm-start"
                                src={testimonial} width="100" alt="services" /><img
                                    className="me-sm-3 my-2 my-sm-0 align-self-center align-self-sm-start" src={quot}
                                    alt="..." />
                                <div className="flex-1 text-center text-sm-start">
                                    <p className="fs-1 text-1200">I would like to thank you both for your cooperation, we'd really like to work with you again.”</p>
                                    <h6 className="mb-1 fw-bold fs-0">Amber Jain</h6>
                                    {/* <h6 className="fs-0">Director, Plugbase</h6> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-6">
                            <div className="d-flex flex-column flex-sm-row align-items-start"><img
                                className="me-sm-3 my-2 my-sm-0 align-self-center align-self-sm-start"
                                src={testimonial} width="100" alt="services" /><img
                                    className="me-sm-3 my-2 my-sm-0 align-self-center align-self-sm-start" src={quot}
                                    alt="..." />
                                <div className="flex-1 text-center text-sm-start">
                                    <p className="fs-1 text-1200">CRO relieved me of frustration of dealing with uncooperative company. CRO helped me to solve the matters.</p>
                                    <h6 className="mb-1 fw-bold fs-0">Arun Mishra</h6>
                                    {/* <h6 className="fs-0">CEO, FrankPhoto</h6> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonials