import React from 'react'
import { Image } from 'react-bootstrap';
import Handshake from '../../assets/img/icons/handshake.png'
import Social from '../../assets/img/icons/social.png'
import Complaint from '../../assets/img/icons/complaint.png'
import Support from '../../assets/img/icons/support.png'

const HowWeWork = () => {
    return (
        <>
            <section id="services" className="services">
                <div className="container">
                    <div className="section-title" data-aos="fade-up">
                        <p style={{ fontFamily: 'poppins' }}>How we work</p>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon-box">
                                <Image src={Handshake} style={{ width: 100, height: 100 }} />
                                <p className="description">We will contact the opposite party, as you are our member we will strongly find out the way to resolve your complaint.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="200">
                            <div className="icon-box">
                                <Image src={Social} style={{ width: 100, height: 100 }} />
                                <p className="description">
                                    As you are our member we will raise your voice in social media platforms like twitter/fb/insta etc.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="300">
                            <div className="icon-box">
                                <Image src={Complaint} style={{ width: 100, height: 100 }} />
                                <p className="description">
                                    We will draft a notice as per your complaint and send it to the opposite party.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="400">
                            <div className="icon-box">
                                <Image src={Support } style={{ width: 100, height: 100 }} />
                                <p className="description">
                                    However if needed, our dedicated team will assist you in attaining justice in the consumer commission or appropriate authorities.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HowWeWork