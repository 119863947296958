import React, { useEffect } from 'react'
import Navbar from './navbar'
import Footer from './footer'

const RefundPolicy = () => {

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <>
            <Navbar />
            <div className="container mt-10">
                <h1 className='text-center mb-5' style={{ fontWeight: "800" }}>Refund Policy</h1>
                <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>After access is granted, due to the sensitive, proprietary nature of the copyrighted content, CRO will not refund the membership fees for any reason.</p>
                <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>Your membership will be valid for one year and you will be liable to all the responsibilities and obligations of the CRO. However if you wish to continue working with CRO for the second year or more then you will have to renew your existing membership.</p>
                <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>No cash will be accepted in CRO.</p>
            </div>
            <Footer />
        </>
    )
}

export default RefundPolicy