import { useQuery } from "@apollo/client";
import React from "react";
import { Table, Spinner } from "react-bootstrap";
import { GET_CONTACT } from "../../../../graphql/Query";

const Contact_details = () => {
  const { data } = useQuery(GET_CONTACT);

  return (
    <>
      <Table bordered style={{ fontSize: 12 }}>
        <thead>
          <tr>
            <th>S.NO.</th>
            <th>Full Name</th>
            <th>Email</th>
            <th>Conatct</th>
            <th colSpan={6}>Message</th>
          </tr>
        </thead>
        <tbody>
          {data ? (
            data.getContact
              .slice()
              .reverse()
              .map((contact, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{contact.name}</td>
                  <td>{contact.email}</td>
                  <td>{contact.phone}</td>
                  <td>{contact.msg}</td>
                </tr>
              ))
          ) : (
            <div
              style={{
                width: "100%",
                position: "absolute",
              }}
              className="d-flex justify-content-center align-items-center mt-4"
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default Contact_details;
