import React from 'react'
import './marquee.css'
function Marqee () {
  return (
    <div class='marquee-l-container'>
      <div>
        <span className='marquee-r-2'>
          Registration is now open for the <b> " World Consumer Day "</b> event on
          15th March 2024 in New Delhi. To register, please{' '}
          <a href='/organisation/reg/' target='_blank' rel='noopener noreferrer'>
            click here
          </a>
          .
        </span>

        <span className='marquee-r-1'>
          Registration is now open for the <b>" World Consumer Day "</b> event on
          15th March 2024 in New Delhi. To register, please{' '}
          <a href='/organisation/reg/' target='_blank' rel='noopener noreferrer'>
            click here
          </a>
          .
        </span>
      </div>
    </div>
  )
}

export default Marqee
