import React, { useEffect } from 'react'
import Navbar from './navbar'
import Footer from './footer'

const Privacy = () => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  return (
    <>
      <Navbar />
      <div className="container mt-10">
        <h1 className='text-center mb-5' style={{ fontWeight: "800" }}>Privacy Policy</h1>
        <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>At CRO, we are committed to protecting your personal data and respecting your privacy. Please read the following terms to understand this better :-</p>
        <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>We use socially reasonable efforts to ensure that the collection of Personal Information is limited to that which is necessary to fulfill the purposes identified below. If we use or plan to use your information in a manner different than the purpose for which it is collected, then we will ask you for your consent prior to such use.</p>
        <p style={{ fontSize: ".95rem", lineHeight: "1.8", color: "rgb(94, 94, 94)", marginBottom: "1.5rem" }}>The Personal Information collected will be used only for the purpose of the organization betterment enabling you to use the information provided by us, to help promote consumer awareness, calibrate consumer interest in our policy and work, inform you about activities and program, We can use and publish this information to prepare reports for consumer behavior, policy drafting from time to time</p>
      </div>
      <Footer />
    </>
  )
}

export default Privacy