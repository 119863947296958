import React from 'react';
import { Container } from 'react-bootstrap';
import { Link,useLocation } from 'react-router-dom'; // Import Link from React Router

function FailedPage() {
    const location = useLocation();
    
    // Extract query parameters from the location object
    const searchParams = new URLSearchParams(location.search);
    const payment_id = searchParams.get('payment_id');
    const amount = searchParams.get('amount');
    return (
        <Container className='d-flex align-items-center justify-content-center' style={{ minHeight: '100vh' }}>
            <div className="card p-4 rounded-3" style={{ maxWidth: '30rem', width: '90%', textAlign: 'center' }}>
                <div style={{ borderRadius: '50%', height: '160px', width: '160px', background: '#F8FAF5', margin: '0 auto' }}>
                    <i style={{ fontSize: '42px', lineHeight: '160px', color: '#FF6347' }}>X</i>
                </div>
                <h1 className='mt-3 mb-0 text-danger'>Transaction Failed</h1>
                <p className="mb-3">Amount: {amount} INR<br />Payment ID: {payment_id}</p>
                <p className="mb-3">Your transaction failed; please try again.</p>
                <div className="d-flex justify-content-center">
                    {/* Link button */}
                    <Link to="/complaint/userLogin" className="btn btn-sm btn-primary">Retry</Link>
                </div>
            </div>
        </Container>
    );
}

export default FailedPage;
