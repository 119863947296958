import React, { useEffect } from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import WoWeAre from "./woWeAre";
import Team from "./team";
import { Carousel, Container, Image } from "react-bootstrap";
import "../../assets/css/about.css";

import Image1 from "../../assets/img/gallery/image1.jpg";
import Image2 from "../../assets/img/gallery/image2.jpg";
import Image3 from "../../assets/img/gallery/complain psd.jpg";
// import Image4 from "../../assets/img/gallery/ecommerce poster english.jpg";
// import Image5 from "../../assets/img/gallery/point002.jpg";
// import Image6 from "../../assets/img/gallery/point009.jpg";
// import Image7 from "../../assets/img/gallery/point003.jpg";
// import Image8 from "../../assets/img/gallery/fssaifood.jpg";
// import Image9 from "../../assets/img/gallery/point006.jpg";
// import Image10 from "../../assets/img/gallery/point005.jpg";
// import Image11 from "../../assets/img/gallery/point008.jpg";
// import Image12 from "../../assets/img/gallery/point010.jpg";

const About = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <Container name="about-section" fluid style={{ marginTop: 120 }}>
        <Carousel>
          <Carousel.Item style={{ height: 450, width: "100%" }}>
            <Image src={Image1} alt="" fluid />
          </Carousel.Item>
          <Carousel.Item>
            <Image src={Image2} alt="" fluid />
          </Carousel.Item>
          <Carousel.Item>
            <Image src={Image3} alt="" fluid />
          </Carousel.Item>
        </Carousel>
      </Container>
      <WoWeAre />
      <section
        id="services"
        className="services section-bg mt-5"
        style={{ marginTop: "-50px" }}
      >
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <p style={{ fontFamily: "poppins" }}>What We do?</p>
          </div>
          <div className="row">
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="icon-box">
                <h4 className="title fs-1 text-primary">
                  <a href="#" className="text-primary">
                    PROBLEMS WE ARE SOLVING
                  </a>
                </h4>
                <p className="description text-start">
                  <ul>
                    <li style={{ listStyle: "initial" }}>
                      {" "}
                      Consumer complaints not resolved
                    </li>
                    <li style={{ listStyle: "initial" }}>
                      {" "}
                      Lengthy and complex process
                    </li>
                    <li style={{ listStyle: "initial" }}>
                      {" "}
                      Inefficient and time consuming
                    </li>
                    <li style={{ listStyle: "initial" }}>
                      {" "}
                      Limited access to resources
                    </li>
                  </ul>
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div className="icon-box">
                <h4 className="title fs-1 text-primary">
                  <a href="" className="fw-bold text-primary">
                    OUR GOAL
                  </a>
                </h4>
                <p className="description text-start">
                  <ul>
                    <li style={{ listStyle: "initial" }}>
                      {" "}
                      Empowering consumer to voice concerns
                    </li>
                    <li style={{ listStyle: "initial" }}>
                      {" "}
                      Facilitating prompt and efficient resolution
                    </li>
                    <li style={{ listStyle: "initial" }}>
                      Promoting corporate reasonability
                    </li>
                    <li style={{ listStyle: "initial" }}>
                      Advocating for consumer rights
                    </li>
                  </ul>
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div className="icon-box">
                <h4 className="title fs-1 text-primary">
                  <a href="" className="fw-bold text-primary">
                    OUR SOLUTION
                  </a>
                </h4>
                <p className="description text-start">
                  <ul>
                    <li style={{ listStyle: "initial" }}>
                      Online grievance redressal platform
                    </li>
                    <li style={{ listStyle: "initial" }}>
                      User Friendly Interface
                    </li>
                    <li style={{ listStyle: "initial" }}>
                      Streamlined complaint filing process
                    </li>
                    <li style={{ listStyle: "initial" }}>
                      Quick and efficient resolution
                    </li>
                    <li style={{ listStyle: "initial" }}>
                      Access to element resources
                    </li>
                  </ul>
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="400"
            >
              <div className="icon-box">
                <h4 className="title fs-1 text-primary">
                  <a href="" className="fw-bold text-primary">
                    KEY FEATURES
                  </a>
                </h4>
                <p className="description text-start">
                  <ul>
                    <li style={{ listStyle: "initial" }}>
                      Easy registration and login
                    </li>
                    <li style={{ listStyle: "initial" }}>
                      Self service and user friendly portal
                    </li>
                    <li style={{ listStyle: "initial" }}>
                      Secure complaint filing system
                    </li>
                    <li style={{ listStyle: "initial" }}>
                      Automated complaint tracking
                    </li>
                    <li style={{ listStyle: "initial" }}>
                      Resource center with FAQs
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Team />
      <Footer />
    </>
  );
};

export default About;
