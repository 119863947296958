import React from 'react'
import edit from '../../assets/img/icons/edit.svg';
import moniter from '../../assets/img/icons/monitor.svg';
import cloud from '../../assets/img/icons/cloud.svg';
import {Row, Col } from 'react-bootstrap'

const ThreeSteps = () => {
    return (
        <>
            <div className="container">
                <Row style={{ marginTop: 50 }}>
                    <h6 style={{ fontFamily: 'poppins', fontWeight: 'bold', fontSize: 25, textAlign: 'center' }}>File a complaint in just three steps</h6>
                    <Col md={4}>
                        <img className="mx-auto d-block" style={{ width: 60, marginTop: 20 }} src={edit} alt="..." />
                        <h4 style={{ textAlign: 'center', fontFamily: 'poppins', fontWeight: 'bold', marginTop: 20 }}>Become a member</h4>
                        <p style={{ textAlign: 'center', fontFamily: 'poppins' }}>To submit your complaint you have to become our member.</p>
                    </Col>
                    <Col md={4}>
                        <img className="mx-auto d-block" style={{ width: 60, marginTop: 20 }} src={moniter} alt="..." />
                        <h4 style={{ textAlign: 'center', fontFamily: 'poppins', fontWeight: 'bold', marginTop: 20 }}>File a complaint</h4>
                        <p style={{ textAlign: 'center', fontFamily: 'poppins' }}>Submit all details with information and upload relevant documents.</p>
                    </Col>
                    <Col md={4}>
                        <img className="mx-auto d-block" style={{ width: 60, marginTop: 20 }} src={cloud} alt="..." />
                        <h4 style={{ textAlign: 'center', fontFamily: 'poppins', fontWeight: 'bold', marginTop: 20 }}>Track your complaint</h4>
                        <p style={{ textAlign: 'center', fontFamily: 'poppins' }}>Track your complaint status in your dashboard real-time.</p>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default ThreeSteps