import React from 'react';
import tourism from '../../assets/img/icons/tourism.png';
import emp from '../../assets/img/icons/labor.png';
import education from '../../assets/img/icons/education.png';
import real from '../../assets/img/icons/real.png';
import fuel from '../../assets/img/icons/petrol.png';
import health from '../../assets/img/icons/sector/health-care.svg';
import train from '../../assets/img/icons/train.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import '../../assets/css/sector.css';

const Sectors = () => {
    return (
        <>
            <section id="services" className="services section-bg" style={{marginTop:30}}>
                <div className="container">
                    <div className="section-title" data-aos="fade-up">
                        <p> <span>Know your <span className='sectorWord'>right,</span>
                            {/* <Typewriter
                                words={[' right']}
                                loop={-1}
                                typeSpeed={50}
                                deleteSpeed={30}
                            /> */}
                        </span>
                            <span> Raise your
                                {/* <Typewriter
                                    words={[' voice']}
                                    loop={-1}
                                    typeSpeed={50}
                                    deleteSpeed={30}
                                /> */} <span className='sectorWord'>voice,</span>
                            </span>
                            <span> We are
                                {/* <Typewriter
                                    words={[' with you']}
                                    loop={-1}
                                    typeSpeed={50}
                                    deleteSpeed={30}
                                /> */} <span className='sectorWord'>with you</span>
                            </span>
                        </p>
                    </div>
                    <div className="row">

                        <div className="swiper-main">
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={10}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                breakpoints={{
                                    '@0.00': {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    '@0.75': {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    '@1.00': {
                                        slidesPerView: 3,
                                        spaceBetween: 40,
                                    },
                                    '@1.50': {
                                        slidesPerView: 4,
                                        spaceBetween: 50,
                                    },
                                }}
                                modules={[Autoplay, Pagination]}
                                className="mySwiper"
                            >
                                <SwiperSlide className="slide">
                                    <img src={real} alt="" style={{ width: 80, height: 80 }} />
                                    <h1 style={{ fontFamily: 'poppins', fontWeight: 'bold', color: '#4e4039', fontSize:15 }}>Real Estate</h1>
                                </SwiperSlide>
                                <SwiperSlide className="slide">
                                    <img src={tourism} alt="" style={{ width: 80, height: 80 }} />
                                    <h1 style={{ fontFamily: 'poppins', fontWeight: 'bold', color: '#4e4039', fontSize:15 }}>Tourism</h1>
                                </SwiperSlide>
                                <SwiperSlide className="slide">
                                    <img src={education} alt="" style={{ width: 80, height: 80 }} />
                                    <h1 style={{ fontFamily: 'poppins', fontWeight: 'bold', color: '#4e4039', fontSize:15 }}>Education</h1>
                                </SwiperSlide>
                                <SwiperSlide className="slide">
                                    <img src={fuel} alt="" style={{ width: 80, height: 80 }} />
                                    <h1 style={{ fontFamily: 'poppins', fontWeight: 'bold', color: '#4e4039', fontSize:15 }}>Environment & Natural Resources</h1>
                                </SwiperSlide>
                                <SwiperSlide className="slide">
                                    <img src={train} alt="" style={{ width: 80, height: 80 }} />
                                    <h1 style={{ fontFamily: 'poppins', fontWeight: 'bold', color: '#4e4039', fontSize:15 }}>Transport & Infrastructure</h1>
                                </SwiperSlide>
                                <SwiperSlide className="slide">
                                    <img src={health} alt="" style={{ width: 80, height: 80 }} />
                                    <h1 style={{ fontFamily: 'poppins', fontWeight: 'bold', color: '#4e4039', fontSize:15 }}>Health & Family welfare</h1>
                                </SwiperSlide>
                                <SwiperSlide className="slide">
                                    <img src={emp} alt="" style={{ width: 80, height: 80 }} />
                                    <h1 style={{ fontFamily: 'poppins', fontWeight: 'bold', color: '#4e4039', fontSize:15 }}>Employment & Labour</h1>
                                </SwiperSlide>
                            </Swiper>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Sectors