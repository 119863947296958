import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
  Spinner,
  Badge,
} from "react-bootstrap";
import { FaEye, FaExchangeAlt, FaEdit } from "react-icons/fa";
import {
  GET_PENDING_COMPALINT,
  GET_ACCEPTED_COMPALINT,
  GET_DECLINED_COMPALINT,
  GET_PAID_COMPALINT,
  GET_UNPAID_COMPALINT,
  GET_RESOLVED_COMPALINT,
  GET_ALL_COMPLAINTS,
  GET_USER_BY_ID,
} from "../../../../graphql/Query";
import {
  COMPLAINT_STATUS_CHANGE,
  UPDATE_COMPLAINT_REASON,
} from "../../../../graphql/Mutation";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useAlert } from "react-alert";

import Moment from "react-moment";
import { FaDownload } from "react-icons/fa6";
import UserDetailsModal from "./UserDetailsModal";

const AllComplaints = () => {
  const [searchComplaintId, setSearchComplaintId] = useState("");
  const [searchType, setSearchType] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const alert = useAlert();
  const { data, loading } = useQuery(GET_ALL_COMPLAINTS, {
    pollInterval: 300,
  });

  const [complaintIdState, setComplaintIdState] = useState("");
  const [statusNameState, setStatusNameState] = useState("");
  const [reason, setReason] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showDecline, setShowDecline] = useState(false);
  const handleCloseDecline = () => setShowDecline(false);
  const handleShowDecline = () => setShowDecline(true);

  const [changeStatus] = useMutation(COMPLAINT_STATUS_CHANGE, {
    refetchQueries: [
      GET_PENDING_COMPALINT,
      GET_ACCEPTED_COMPALINT,
      GET_DECLINED_COMPALINT,
      GET_PAID_COMPALINT,
      GET_UNPAID_COMPALINT,
      GET_RESOLVED_COMPALINT,
    ],
  });
  const [updateReason] = useMutation(UPDATE_COMPLAINT_REASON, {
    refetchQueries: [
      GET_PENDING_COMPALINT,
      GET_ACCEPTED_COMPALINT,
      GET_DECLINED_COMPALINT,
      GET_PAID_COMPALINT,
      GET_UNPAID_COMPALINT,
      GET_RESOLVED_COMPALINT,
    ],
  });

  function clickShowModal(cId) {
    setComplaintIdState(cId);
    handleShow();
  }

  function changeStatusHandel() {
    changeStatus({
      variables: {
        complaintId: `${complaintIdState}`,
        statusName: `${statusNameState}`,
      },
    }).then(() => {
      handleClose();
    });
  }

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedComplaint, setSelectedComplaint] = useState({});

  const clickShowDetailsModal = (cId) => {
    console.log("clicked data", cId);
    const selectedComplaint = data.getAllComplaint.find(
      (Cdata) => Cdata._id === cId
    );
    setSelectedComplaint(selectedComplaint);
    setShowDetailsModal(true);
    console.log(selectedComplaint);
  };

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      updateReason({
        variables: {
          complaintId: `${complaintIdState}`,
          reason: `${reason}`,
        },
      }).then(() => {
        changeStatus({
          variables: {
            complaintId: `${complaintIdState}`,
            statusName: `${statusNameState}`,
          },
        }).then(() => {
          setReason("");
          alert.success("Reason submitted successfully");
          handleClose();
          handleCloseDecline();
        });
      });
    }
  };

  console.log(data);

  const statusOptions = ["pending", "accepted", "declined", "resolved"];

  // Filtered data based on search inputs
  const filteredData = data
    ? data.getAllComplaint.filter(
        (Cdata) =>
          Cdata.complaintId.toLowerCase().includes(searchComplaintId) &&
          Cdata.complaintType.toLowerCase().includes(searchType) &&
          Cdata.status.toLowerCase().includes(searchStatus)
      )
    : [];

  // Event handlers for search input changes
  const handleComplaintIdSearchChange = (event) => {
    setSearchComplaintId(event.target.value.toLowerCase());
  };

  const handleTypeSearchChange = (event) => {
    setSearchType(event.target.value.toLowerCase());
  };

  const handleStatusChange = (event) => {
    setSearchStatus(event.target.value.toLowerCase());
  };

  //TODO: User detail modal start

  const [getUserById, { data: userData }] = useLazyQuery(GET_USER_BY_ID);

  const [userDetailData, setUserDetailData] = useState();
  const [showUserDetails, setShowUserDetails] = useState(false);

  const UserDataHandler = useCallback((userId) => {
    getUserById({
      variables: {
        userId: userId,
      },
    }).then((res) => {
      setUserDetailData(res?.data?.getUserById);
      setShowUserDetails(true);
    });
  });

  //TODO: User detail modal end

  return (
    <>
      {/* User detail modal --start */}
      {showUserDetails ? (
        <UserDetailsModal
          show={showUserDetails}
          setShow={setShowUserDetails}
          Data={userDetailData}
        />
      ) : null}
      {/* User detail modal --end */}
      <Container>
        <Row>
          <Col>
            <h1
              style={{
                fontFamily: "poppins",
                fontSize: 20,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              All Complaints
            </h1>
            <hr />
            <Row className="mb-4 mt-4">
              <Col md={4}>
                <p className="m-0">Search by Compaint ID :-</p>
                <input
                  type="text"
                  placeholder="Search by Complaint ID"
                  className="m-0 mt-2"
                  style={{ background: "#e8e8e8", color: "#000" }}
                  onChange={handleComplaintIdSearchChange}
                />
              </Col>
              <Col md={4}>
                <p className="m-0">Search by Type :-</p>
                <input
                  type="text"
                  placeholder="Search by Type"
                  className="m-0 mt-2"
                  style={{ background: "#e8e8e8", color: "#000" }}
                  onChange={handleTypeSearchChange}
                />
              </Col>
              <Col md={4}>
                <p className="m-0">Search by Status :-</p>
                <select
                  className="m-0 mt-2 py-2"
                  style={{
                    background: "#e8e8e8",
                    color: "#000",
                    width: "100%",
                  }}
                  onChange={handleStatusChange}
                  defaultValue=""
                >
                  <option value="" disabled>
                    Select Status
                  </option>
                  {statusOptions.map((status, index) => (
                    <option key={index} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            <hr className="mb-4" />
            <Table bordered style={{ fontFamily: "poppins", fontSize: 12 }}>
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th className="complaint_id">Complaint Id</th>
                  <th>Date/Time</th>
                  <th>Type</th>
                  <th>Membership</th>
                  <th>IsUser</th>
                  <th>Status</th>
                  {/* <th>View</th>
                  <th>User Complaint</th> */}
                  <th colSpan={2}>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 &&
                  filteredData
                    .slice()
                    .reverse()
                    .map((Cdata, index) => (
                      <tr key={Cdata.id} className="complaint_row">
                        <td>{index + 1}</td>
                        <td>{Cdata.complaintId}</td>
                        <td>
                          <Moment format="DD-MM-YYYY, hh:mm A">
                            {Cdata.createdDateTime}
                          </Moment>
                        </td>
                        <td>{Cdata.complaintType}</td>
                        <td>
                          {Cdata.isPremium ? (
                            <Badge bg="success">Membership</Badge>
                          ) : (
                            <Badge bg="danger">No Membership</Badge>
                          )}
                        </td>
                        <td>
                          {Cdata.isExistUser ? (
                            <Badge
                              style={{ cursor: "pointer" }}
                              onClick={() => UserDataHandler(Cdata.userId._id)}
                              bg="success"
                            >
                              User
                            </Badge>
                          ) : (
                            <Badge bg="danger">Not a User</Badge>
                          )}
                        </td>
                        <td>{Cdata.status}</td>
                        <td>
                          <FaEye
                            onClick={() => clickShowDetailsModal(Cdata._id)}
                            style={{ cursor: "pointer" }}
                            size={22}
                            color="blue"
                          />
                        </td>
                        <td>
                          <FaEdit
                            onClick={() => clickShowModal(Cdata._id)}
                            style={{ cursor: "pointer" }}
                            size={22}
                          />
                        </td>
                      </tr>
                    ))}

                {filteredData.length === 0 && !loading && (
                  <tr>
                    <td colSpan={9}>No Data Found.</td>
                  </tr>
                )}

                {loading && (
                  <div
                    style={{
                      width: "100%",
                      position: "absolute",
                    }}
                    className="d-flex justify-content-center align-items-center mt-4"
                  >
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <Form.Control
            required
            as="select"
            style={{ fontFamily: "poppins", fontSize: 12 }}
            onChange={(e) => setStatusNameState(e.target.value)}
            value={statusNameState}
          >
            <option value="" selected="selected" disabled="disabled">
              Choose Status
            </option>
            <option value="accepted">Accepted</option>
            <option value="declined">Declined</option>
            <option value="paid">Paid</option>
            <option value="unpaid">Unpaid</option>
            <option value="resolved">Resolved</option>
          </Form.Control>
          {statusNameState === "declined" ? (
            <Button
              onClick={() => handleShowDecline()}
              className="mx-auto d-block"
              style={{ marginTop: 20 }}
            >
              Give Reason
            </Button>
          ) : (
            <Button
              onClick={() => changeStatusHandel()}
              className="mx-auto d-block"
              style={{ marginTop: 20 }}
            >
              Change
            </Button>
          )}
        </Modal.Body>
      </Modal>

      {/* modal for show the details of the complaint */}

      <Modal
        show={showDetailsModal}
        onHide={() => setShowDetailsModal(false)}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ width: "100%" }}>
          <Col md={12}>
            <Row md={12}>
              <h1
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontFamily: "poppins",
                  fontSize: 25,
                }}
              >
                Complaint Details
              </h1>
              <Table
                bordered
                style={{ fontFamily: "poppins", fontSize: 12 }}
                responsive
              >
                <tbody>
                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      {" "}
                      Complainer Details
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <p>
                        <b>Full Name:</b> {selectedComplaint?.fullName}
                      </p>
                    </td>
                    <td colSpan={3}>
                      <p>
                        <b>Phone Number:</b> {selectedComplaint?.phone_number}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={6}>
                      <p>
                        <b>Email:</b> {selectedComplaint?.email}
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Details of opposite party
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>
                      Company/Firm/Service Provider Name
                    </td>
                    <td>{selectedComplaint.yourProviderName}</td>
                    <td style={{ fontWeight: "bold" }}>Email</td>
                    <td>{selectedComplaint.yourEmail}</td>
                    <td style={{ fontWeight: "bold" }}>Contact</td>
                    <td>{selectedComplaint.yourContact}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>
                      Name of concern person
                    </td>
                    <td>{selectedComplaint.concernPerson}</td>
                    <td style={{ fontWeight: "bold" }}>Name of product</td>
                    <td>{selectedComplaint.productName}</td>
                    <td style={{ fontWeight: "bold" }}>Address</td>
                    <td>{selectedComplaint.yourFullAddress}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>City</td>
                    <td>{selectedComplaint.yourCity}</td>
                    <td style={{ fontWeight: "bold" }}>State</td>
                    <td>{selectedComplaint.yourState}</td>
                    <td style={{ fontWeight: "bold" }}>Pincode</td>
                    <td>{selectedComplaint.yourPincode}</td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      {" "}
                      Have You Complained To Anyother Platform Earlier
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>
                      Have You Complained To Anyother Platform Earlier
                    </td>
                    <td>{selectedComplaint.haveYouComplaintToAnother}</td>
                    <td style={{ fontWeight: "bold" }}>Name of Platform</td>
                    <td colspan={3}>
                      {selectedComplaint.haveYouComplaintToAnotherText}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Resolution
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Resolution</td>
                    {selectedComplaint.resolution ? (
                      <td>
                        {selectedComplaint &&
                          selectedComplaint.resolution.map((d) => (
                            <h6 style={{ fontFamily: "poppins" }}>{d},</h6>
                          ))}
                      </td>
                    ) : (
                      ""
                    )}
                    <td style={{ fontWeight: "bold" }}>Name of Platform</td>
                    <td colspan={3}>
                      {selectedComplaint.haveYouComplaintToAnotherText}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Product/Service Name
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>
                      {selectedComplaint.haveStartCourtActionText}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Product Or Service Value (INR)
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>₹{selectedComplaint.productValue}</td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      How Would You Like Your Complaint To Get Solved?
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>
                      ₹{selectedComplaint.howYouLikeComplaintSolve}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      When Did This Transaction / Problem Occur?
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Date</td>
                    <td>{selectedComplaint.whenProblemOccurDate}</td>
                    <td style={{ fontWeight: "bold" }}>Time</td>
                    <td colspan={3}>
                      {selectedComplaint.whenProblemOccurTime}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Select A Type Which Is Most Relevant To Your Complaint
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>{selectedComplaint.complaintType}</td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Please Describe Your Complaint In Detail
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>
                      {selectedComplaint.complaintDescription}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Attechments
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9} className="d-flex">
                      {selectedComplaint &&
                        selectedComplaint.image &&
                        selectedComplaint.image.length > 0 &&
                        selectedComplaint.image.map((r) => (
                          <div
                            className="mt-3 position-relative ms-3"
                            style={{ height: 100, width: 100 }}
                          >
                            <img
                              src={
                                r
                                  ? `https://cro-org-bucket.s3.ap-south-1.amazonaws.com/${r}`
                                  : "https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg"
                              }
                              alt=""
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                              }}
                            />
                            <div
                              style={{
                                height: 25,
                                width: 25,
                                background: "white",
                                position: "absolute",
                                right: 0,
                                top: 0,
                                cursor: "pointer",
                              }}
                              className="d-flex justify-content-center align-items-center"
                              onClick={() =>
                                window.open(
                                  `https://cro-org-bucket.s3.ap-south-1.amazonaws.com/${
                                    r ? r : ""
                                  }`,
                                  "_blank"
                                )
                              }
                            >
                              <FaDownload />
                            </div>
                          </div>
                        ))}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>

      <Modal show={showDecline} onHide={handleCloseDecline} centered size="lg">
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label style={{ fontFamily: "poppins", fontSize: 12 }}>
                  Enter your reason
                </Form.Label>
                <Form.Control
                  style={{ fontFamily: "poppins", fontSize: 12 }}
                  required
                  as="textarea"
                  placeholder="Enter your reason"
                  onChange={(e) => setReason(e.target.value)}
                  value={reason}
                />
              </Form.Group>
            </Row>
            <Button
              style={{ fontFamily: "poppins", fontSize: 12 }}
              className="mx-auto d-block"
              type="submit"
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AllComplaints;
