import React from "react";
import { Button, Container, Modal, Row } from "react-bootstrap";

export default function UserDetailModal({ show, setShow, Data }) {
  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>User Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <p>
            <b>Full Name:</b> {Data?.fullName}
          </p>
          <p>
            <b>Phone Number:</b> {Data?.contact}
          </p>
          <p>
            <b>Email:</b> {Data?.email}
          </p>
          <p>
            <b>Status:</b> {Data?.status}
          </p>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
