import { gql } from "@apollo/client";

export const GET_USER = gql`
query GetAllUsers {
  getAllUsers {
    _id
    fullName
    fatherName
    dob
    occupation
    contact
    email
    bloodGroup
    password
    addressLine1
    addressLine2
    city
    state
    pincode
    idProof
    attachIdProofImage
    attachPassportImage
    createdDateTime
    status
    isPremium
    isExistUser
    payments {
      id
      amount
      currency
      receipt
      amount_paid
      amount_due
      offer_id
      status
      attempts
      notes
      created_at
      expire_at
      currentStatus
      orderId
    }
  }
}
`;

export const GET_ALL_CHECKOUT = gql`
  query GetAllCheckout {
    getAllCheckout {
      id
      orderId {
        id
        orderId
        entity
        amount
        amount_paid
        amount_due
        currency
        receipt
        offer_id
        status
        attempts
        notes
        created_at
      }
      email
      razorpay_order_id
      razorpay_payment_id
      razorpay_signature
      created_at
    }
  }
`;

export const GET_USER_BY_EMAIL = gql`
  query GetUserByEmail($email: String) {
    getUserByEmail(Email: $email) {
      fullName
      email
      contact
      status
    }
  }
`;

export const GET_ALL_FAILED_TRANSACTION = gql`
  query GetOrderByStatus($status: String!) {
    getOrderByStatus(status: $status) {
      id
      orderId
      entity
      amount
      amount_paid
      amount_due
      currency
      receipt
      offer_id
      status
      attempts
      notes
      created_at
    }
  }
`;

export const GET_ALL_TRANSACTIONS = gql`
  query GetAllOrder {
    getAllOrder {
      id
      orderId
      entity
      amount
      amount_paid
      amount_due
      currency
      receipt
      offer_id
      status
      attempts
      notes
      created_at
    }
  }
`;

export const GET_ALL_COMPLAINTS = gql`
  query GetAllComplaint {
    getAllComplaint {
      _id
      userId {
        _id
        email
        fullName
      }
      complaintId
      yourProviderName
      yourEmail
      yourContact
      concernPerson
      productName
      yourFullAddress
      yourCity
      yourState
      yourPincode
      haveYouComplaintToAnother
      haveYouComplaintToAnotherText
      resolution
      productValue
      image
      whoFullName
      whoEmail
      whoContact
      whoFullAddress
      whoNameofOpposite
      whoNameofProduct
      whoPincode
      whoState
      whoCity
      whenProblemOccurDate
      whenProblemOccurTime
      didSignAggrement
      haveStartCourtAction
      haveStartCourtActionText
      complaintType
      complaintDescription
      complaintFile
      howYouLikeComplaintSolve
      complaintReason
      createdDateTime
      status
      fullName
      email
      phone_number
      isPremium
      isExistUser
    }
  }
`;

export const GET_ALL_RESOLVED_COMPLAINTS = gql`
  query GetAllResolvedComplaint {
    getAllResolvedComplaint {
      id
      userId
      complaintId
      yourProviderName
      yourEmail
      yourContact
      concernPerson
      productName
      yourFullAddress
      yourCity
      yourState
      yourPincode
      haveYouComplaintToAnother
      haveYouComplaintToAnotherText
      resolution
      productValue
      image
      whoFullName
      whoEmail
      whoContact
      whoFullAddress
      whoNameofOpposite
      whoNameofProduct
      whoPincode
      whoState
      whoCity
      whenProblemOccurDate
      whenProblemOccurTime
      didSignAggrement
      haveStartCourtAction
      haveStartCourtActionText
      complaintType
      complaintDescription
      complaintFile
      howYouLikeComplaintSolve
      complaintReason
      createdDateTime
      status
    }
  }
`;

export const GET_ALL_PENDING_COMPLAINTS = gql`
  query GetAllPendingComplaint {
    getAllPendingComplaint {
      id
      userId
      complaintId
      yourProviderName
      yourEmail
      yourContact
      concernPerson
      productName
      yourFullAddress
      yourCity
      yourState
      yourPincode
      haveYouComplaintToAnother
      haveYouComplaintToAnotherText
      resolution
      productValue
      image
      whoFullName
      whoEmail
      whoContact
      whoFullAddress
      whoNameofOpposite
      whoNameofProduct
      whoPincode
      whoState
      whoCity
      whenProblemOccurDate
      whenProblemOccurTime
      didSignAggrement
      haveStartCourtAction
      haveStartCourtActionText
      complaintType
      complaintDescription
      complaintFile
      howYouLikeComplaintSolve
      complaintReason
      createdDateTime
      status
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query GetUserById($userId: ID) {
    getUserById(userId: $userId) {
      _id
      fullName
      fatherName
      dob
      occupation
      contact
      email
      bloodGroup
      password
      addressLine1
      addressLine2
      city
      state
      pincode
      idProof
      attachIdProofImage
      attachPassportImage
      createdDateTime
      status
      payments {
        id
        amount
        currency
        receipt
        amount_paid
        amount_due
        offer_id
        status
        attempts
        notes
        created_at
      }
    }
  }
`;

export const GET_PENDING_COMPALINT_BY_USERID = gql`
  query Query($userId: ID) {
    getPendingComplaintByUserId(userId: $userId) {
      id
      userId
      complaintId
      yourProviderName
      yourEmail
      yourContact
      concernPerson
      productName
      yourFullAddress
      yourCity
      yourState
      yourPincode
      haveYouComplaintToAnother
      haveYouComplaintToAnotherText
      resolution
      productValue
      image
      whoFullName
      whoEmail
      whoContact
      whoFullAddress
      whoNameofOpposite
      whoNameofProduct
      whoPincode
      whoState
      whoCity
      whenProblemOccurDate
      whenProblemOccurTime
      didSignAggrement
      haveStartCourtAction
      haveStartCourtActionText
      complaintType
      complaintDescription
      complaintReason
      complaintFile
      howYouLikeComplaintSolve
      createdDateTime
      status
    }
  }
`;

export const GET_ACCEPTED_COMPALINT_BY_USERID = gql`
  query GetAcceptedComplaintByUserId($userId: ID) {
    getAcceptedComplaintByUserId(userId: $userId) {
      id
      userId
      complaintId
      yourProviderName
      yourEmail
      yourContact
      concernPerson
      productName
      yourFullAddress
      yourCity
      yourState
      yourPincode
      haveYouComplaintToAnother
      haveYouComplaintToAnotherText
      resolution
      productValue
      image
      whoFullName
      whoEmail
      whoContact
      whoFullAddress
      whoNameofOpposite
      whoNameofProduct
      whoPincode
      whoState
      whoCity
      whenProblemOccurDate
      whenProblemOccurTime
      didSignAggrement
      haveStartCourtAction
      haveStartCourtActionText
      complaintType
      complaintDescription
      complaintFile
      howYouLikeComplaintSolve
      createdDateTime
      status
    }
  }
`;
export const GET_DECLINED_COMPALINT_BY_USERID = gql`
  query GetDeclinedComplaintByUserId($userId: ID) {
    getDeclinedComplaintByUserId(userId: $userId) {
      id
      userId
      complaintId
      yourProviderName
      yourEmail
      yourContact
      concernPerson
      productName
      yourFullAddress
      yourCity
      yourState
      yourPincode
      haveYouComplaintToAnother
      haveYouComplaintToAnotherText
      resolution
      productValue
      image
      whoFullName
      whoEmail
      whoContact
      whoFullAddress
      whoNameofOpposite
      whoNameofProduct
      whoPincode
      whoState
      whoCity
      whenProblemOccurDate
      whenProblemOccurTime
      didSignAggrement
      haveStartCourtAction
      haveStartCourtActionText
      complaintType
      complaintDescription
      complaintReason
      complaintFile
      howYouLikeComplaintSolve
      createdDateTime
      status
    }
  }
`;
export const GET_PAID_COMPALINT_BY_USERID = gql`
  query GetPaidComplaintByUserId($userId: ID) {
    getPaidComplaintByUserId(userId: $userId) {
      id
      userId
      complaintId
      yourProviderName
      yourEmail
      yourContact
      concernPerson
      productName
      yourFullAddress
      yourCity
      yourState
      yourPincode
      haveYouComplaintToAnother
      haveYouComplaintToAnotherText
      resolution
      productValue
      image
      whoFullName
      whoEmail
      whoContact
      whoFullAddress
      whoNameofOpposite
      whoNameofProduct
      whoPincode
      whoState
      whoCity
      whenProblemOccurDate
      whenProblemOccurTime
      didSignAggrement
      haveStartCourtAction
      haveStartCourtActionText
      complaintType
      complaintDescription
      complaintReason
      complaintFile
      howYouLikeComplaintSolve
      createdDateTime
      status
    }
  }
`;
export const GET_UNPAID_COMPALINT_BY_USERID = gql`
  query GetUnpaidComplaintByUserId($userId: ID) {
    getUnpaidComplaintByUserId(userId: $userId) {
      id
      userId
      complaintId
      yourProviderName
      yourEmail
      yourContact
      concernPerson
      productName
      yourFullAddress
      yourCity
      yourState
      yourPincode
      haveYouComplaintToAnother
      haveYouComplaintToAnotherText
      resolution
      productValue
      image
      whoFullName
      whoEmail
      whoContact
      whoFullAddress
      whoNameofOpposite
      whoNameofProduct
      whoPincode
      whoState
      whoCity
      whenProblemOccurDate
      whenProblemOccurTime
      didSignAggrement
      haveStartCourtAction
      haveStartCourtActionText
      complaintType
      complaintDescription
      complaintReason
      complaintFile
      howYouLikeComplaintSolve
      createdDateTime
      status
    }
  }
`;
export const GET_RESOLVED_COMPALINT_BY_USERID = gql`
  query GetResolvedComplaintByUserId($userId: ID) {
    getResolvedComplaintByUserId(userId: $userId) {
      id
      userId
      complaintId
      yourProviderName
      yourEmail
      yourContact
      concernPerson
      productName
      yourFullAddress
      yourCity
      yourState
      yourPincode
      haveYouComplaintToAnother
      haveYouComplaintToAnotherText
      resolution
      productValue
      image
      whoFullName
      whoEmail
      whoContact
      whoFullAddress
      whoNameofOpposite
      whoNameofProduct
      whoPincode
      whoState
      whoCity
      whenProblemOccurDate
      whenProblemOccurTime
      didSignAggrement
      haveStartCourtAction
      haveStartCourtActionText
      complaintType
      complaintDescription
      complaintReason
      complaintFile
      howYouLikeComplaintSolve
      createdDateTime
      status
    }
  }
`;
export const GET_PENDING_COMPALINT = gql`
  query GetPendingComplaint {
    getPendingComplaint {
      _id
      userId {
        email
        _id
        fullName
      }
      complaintId
      yourProviderName
      yourEmail
      yourContact
      concernPerson
      productName
      yourFullAddress
      yourCity
      yourState
      yourPincode
      haveYouComplaintToAnother
      haveYouComplaintToAnotherText
      resolution
      productValue
      image
      whoFullName
      whoEmail
      whoContact
      whoFullAddress
      whoNameofOpposite
      whoNameofProduct
      whoPincode
      whoState
      whoCity
      whenProblemOccurDate
      whenProblemOccurTime
      didSignAggrement
      haveStartCourtAction
      haveStartCourtActionText
      complaintType
      complaintDescription
      complaintFile
      howYouLikeComplaintSolve
      complaintReason
      createdDateTime
      status
      fullName
      email
      phone_number
      isPremium
      isExistUser
    }
  }
`;

export const GET_ACCEPTED_COMPALINT = gql`
  query GetAcceptedComplaint {
    getAcceptedComplaint {
      _id
      userId {
        _id
        email
        fullName
      }
      complaintId
      yourProviderName
      yourEmail
      yourContact
      concernPerson
      productName
      yourFullAddress
      yourCity
      yourState
      yourPincode
      haveYouComplaintToAnother
      haveYouComplaintToAnotherText
      resolution
      productValue
      image
      whoFullName
      whoEmail
      whoContact
      whoFullAddress
      whoNameofOpposite
      whoNameofProduct
      whoPincode
      whoState
      whoCity
      whenProblemOccurDate
      whenProblemOccurTime
      didSignAggrement
      haveStartCourtAction
      haveStartCourtActionText
      complaintType
      complaintDescription
      complaintFile
      howYouLikeComplaintSolve
      complaintReason
      createdDateTime
      status
      fullName
      email
      phone_number
      isPremium
      isExistUser
    }
  }
`;

export const GET_DECLINED_COMPALINT = gql`
  query GetDeclinedComplaint {
    getDeclinedComplaint {
      _id
      userId {
        _id
        email
        fullName
      }
      complaintId
      yourProviderName
      yourEmail
      yourContact
      concernPerson
      productName
      yourFullAddress
      yourCity
      yourState
      yourPincode
      haveYouComplaintToAnother
      haveYouComplaintToAnotherText
      resolution
      productValue
      image
      whoFullName
      whoEmail
      whoContact
      whoFullAddress
      whoNameofOpposite
      whoNameofProduct
      whoPincode
      whoState
      whoCity
      whenProblemOccurDate
      whenProblemOccurTime
      didSignAggrement
      haveStartCourtAction
      haveStartCourtActionText
      complaintType
      complaintDescription
      complaintFile
      howYouLikeComplaintSolve
      complaintReason
      createdDateTime
      status
      fullName
      email
      phone_number
      isPremium
      isExistUser
    }
  }
`;

export const GET_PAID_COMPALINT = gql`
  query GetPaidComplaint {
    getPaidComplaint {
      id
      userId
      complaintId
      yourProviderName
      yourEmail
      yourContact
      concernPerson
      productName
      yourFullAddress
      yourCity
      yourState
      yourPincode
      haveYouComplaintToAnother
      haveYouComplaintToAnotherText
      resolution
      productValue
      image
      whoFullName
      whoEmail
      whoContact
      whoFullAddress
      whoNameofOpposite
      whoNameofProduct
      whoPincode
      whoState
      whoCity
      whenProblemOccurDate
      whenProblemOccurTime
      didSignAggrement
      haveStartCourtAction
      haveStartCourtActionText
      complaintType
      complaintDescription
      complaintReason
      complaintFile
      howYouLikeComplaintSolve
      createdDateTime
      status
    }
  }
`;

export const GET_UNPAID_COMPALINT = gql`
  query GetUnpaidComplaint {
    getUnpaidComplaint {
      id
      userId
      complaintId
      yourProviderName
      yourEmail
      yourContact
      concernPerson
      productName
      yourFullAddress
      yourCity
      yourState
      yourPincode
      haveYouComplaintToAnother
      haveYouComplaintToAnotherText
      resolution
      productValue
      image
      whoFullName
      whoEmail
      whoContact
      whoFullAddress
      whoNameofOpposite
      whoNameofProduct
      whoPincode
      whoState
      whoCity
      whenProblemOccurDate
      whenProblemOccurTime
      didSignAggrement
      haveStartCourtAction
      haveStartCourtActionText
      complaintType
      complaintDescription
      complaintReason
      complaintFile
      howYouLikeComplaintSolve
      createdDateTime
      status
    }
  }
`;

export const GET_RESOLVED_COMPALINT = gql`
  query GetResolvedComplaint {
    getResolvedComplaint {
      _id
      userId {
        _id
        email
        fullName
      }
      complaintId
      yourProviderName
      yourEmail
      yourContact
      concernPerson
      productName
      yourFullAddress
      yourCity
      yourState
      yourPincode
      haveYouComplaintToAnother
      haveYouComplaintToAnotherText
      resolution
      productValue
      image
      whoFullName
      whoEmail
      whoContact
      whoFullAddress
      whoNameofOpposite
      whoNameofProduct
      whoPincode
      whoState
      whoCity
      whenProblemOccurDate
      whenProblemOccurTime
      didSignAggrement
      haveStartCourtAction
      haveStartCourtActionText
      complaintType
      complaintDescription
      complaintFile
      howYouLikeComplaintSolve
      complaintReason
      createdDateTime
      status
      fullName
      email
      phone_number
      isPremium
      isExistUser
    }
  }
`;

export const GET_CONTACT = gql`
  query GetContact {
    getContact {
      id
      name
      email
      phone
      msg
    }
  }
`;
export const GET_TOKEN = gql`
  query CheckToken($tokenId: String) {
    checkToken(tokenId: $tokenId) {
      id
      userId
      email
      token
      status
    }
  }
`;

export const GET_WDC_REG = gql`
  query Query {
    getWCDRegistration {
      id
      fullName
      email
      phone
      state
      city
      photo
    }
  }
`;

export const GET_PAYMENT_DETAILS = gql`
  query Query($paymentId: String!) {
    fetchPayment(paymentId: $paymentId) {
      id
      entity
      amount
      currency
      status
      captured
    }
  }
`;
