import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client';
import Navbar from './navbar'
import '../../assets/css/componentcss/contact.css'
import Footer from './footer'
import * as animationData from '../../assets/img/anim/contact.json';
import Lottie from 'react-lottie';
import { Container, Row, Col, Card, Button, Form, Spinner } from "react-bootstrap"
import { useAlert } from "react-alert";

import { CREATE_CONTACT, SEND_MAIL_CONTACT } from "../../graphql/Mutation"

const ContactUs = () => {
    const alert = useAlert();
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [msg, setMsg] = useState("")

    const [addContact, { loading }] = useMutation(CREATE_CONTACT)

    const [sendContactUsMail] = useMutation(SEND_MAIL_CONTACT)

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        { window.scroll(0, 0) }
    }, [])


    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setValidated(false);
            event.preventDefault();

            addContact({
                variables: {
                    "contactInput": {
                        "msg": `${msg}`,
                        "name": `${name}`,
                        "email": `${email}`,
                        "phone": `${phone}`
                    }
                }
            }).then(() => {
                sendContactUsMail({
                    variables: {
                        "email": `${email}`
                    }
                }).then(() => {
                    setName("")
                    setEmail("")
                    setPhone("")
                    setMsg("")
                    alert.success("We received your request, we will contact you as soon as possible.");
                })
            }).catch((e) => {
                console.log(e);
            })
        }

    };




    return (
        <>
            <Navbar />
            <Container style={{ marginTop: 150 }}>
                <Row>
                    <Col md={5}>
                        <Row>
                            <Lottie options={defaultOptions} height={300} width={300} />
                        </Row>
                        <Row>
                            <p style={{ fontFamily: 'poppins', fontSize: 15 }}>
                                Thecro.org website is a dedicated consumer complaint portal for the members of the organization. We welcome your questions and comments regarding the complaint website. If you have any questions/queries call or email us.
                            </p>
                            <h1 style={{ fontFamily: 'poppins', fontSize: 20, color: '#e74c3c', fontWeight: 'bold' }}>+91 9977-252-252</h1>
                            <p>office@thecro.org</p>
                        </Row>
                    </Col>

                    <Col md={7}>
                        <Card>
                            <Card.Body>
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                                            <Form.Label style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>First name</Form.Label>
                                            <Form.Control
                                                onChange={(e) => setName(e.target.value)}
                                                value={name}
                                                required
                                                type="text"
                                                placeholder="Full Name"
                                                style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }} />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>Email</Form.Label>
                                            <Form.Control
                                                onChange={(e) => setEmail(e.target.value)}
                                                value={email}
                                                required
                                                type="text"
                                                placeholder="Email"
                                                style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }} />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>Phone Number</Form.Label>
                                            <Form.Control
                                                onChange={(e) => setPhone(e.target.value)}
                                                value={phone}
                                                required
                                                type="number"
                                                placeholder="Phone Number"
                                                style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }} />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                                            <Form.Label style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>Message</Form.Label>
                                            <Form.Control
                                                onChange={(e) => setMsg(e.target.value)}
                                                value={msg}
                                                required
                                                as="textarea"
                                                placeholder="Message"
                                                style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }} />
                                        </Form.Group>
                                    </Row>
                                    {
                                        loading ? (
                                            <Spinner animation="border" role="status" className='mx-auto d-block' style={{ marginTop: 10 }}>
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        ) : (
                                            <Button type="submit" className="loginBtnn mx-auto d-block" style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>Submit</Button>
                                        )
                                    }

                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>

    )
}

export default ContactUs