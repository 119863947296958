import { useMutation, useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { MdDelete } from "react-icons/md";

import {
  Button,
  Table,
  Spinner,
  Modal,
  Row,
  Col,
  Image,
  InputGroup,
  Form,
  Badge,
} from "react-bootstrap";

import { GET_USER } from "../../../../graphql/Query";
import { FaDownload, FaEye } from "react-icons/fa";
import { MUTATION_USER_DELETE } from "../../../../graphql/Mutation";
import Moment from "react-moment";
import moment from "moment";

const MembershipUser = () => {
  const [show, setShow] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState({});
  const { data } = useQuery(GET_USER);
  console.log(data);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (id) => {
    const userDetails = data.getAllUsers.find(
      (userData) => userData._id === id
    );
    setSelectedUserDetails(userDetails);
    console.log("details", selectedUserDetails);
    setShow(true);
  };

  const [foundValue, setFoundValue] = useState();

  useEffect(() => {
    if (data) {
      setFoundValue(data.getAllUsers);
    }
  }, [data]);

  const [searchEmail, setSearchEmail] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchContact, setSearchContact] = useState("");

  const filterByName = (e) => {
    setSearchEmail("");
    setSearchName("");
    setSearchContact("");
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = data.getAllUsers.filter((data) => {
        return data.fullName.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setFoundValue(results);
    } else {
      setFoundValue(data.getAllUsers);
    }
    setSearchName(keyword);
  };

  const filterByEmail = (e) => {
    setSearchEmail("");
    setSearchName("");
    setSearchContact("");
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = data.getAllUsers.filter((data) => {
        return data.email.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setFoundValue(results);
    } else {
      setFoundValue(data.getAllUsers);
    }
    setSearchEmail(keyword);
  };

  const filterByContact = (e) => {
    setSearchEmail("");
    setSearchName("");
    setSearchContact("");
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = data.getAllUsers.filter((data) => {
        return data.contact.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setFoundValue(results);
    } else {
      setFoundValue(data.getAllUsers);
    }
    setSearchContact(keyword);
  };

  const [deleteUser] = useMutation(MUTATION_USER_DELETE, {
    refetchQueries: [GET_USER, "getAllUsers"],
  });

  const handleDelete = (id) => {
    deleteUser({
      variables: {
        userId: `${id}`,
      },
    });
  };

  return (
    <>
      <Table striped bordered hover style={{ fontSize: 12 }}>
        <tbody>
          <tr>
            <td>
              <Row>
                <Col md={4}>
                  {" "}
                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      id="inputGroup-sizing-default"
                      style={{ fontSize: 12 }}
                    >
                      Search By Name
                    </InputGroup.Text>
                    <Form.Control
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      value={searchName}
                      onChange={filterByName}
                      style={{ fontSize: 12 }}
                    />
                  </InputGroup>
                </Col>

                <Col md={4}>
                  {" "}
                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      id="inputGroup-sizing-default"
                      style={{
                        fontSize: 12,
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      Search By Contact
                    </InputGroup.Text>
                    <Form.Control
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      onChange={filterByContact}
                      value={searchContact}
                      style={{
                        fontSize: 12,
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    />
                  </InputGroup>
                </Col>

                <Col md={4}>
                  {" "}
                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      id="inputGroup-sizing-default"
                      style={{
                        fontSize: 12,
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      Search By Email
                    </InputGroup.Text>
                    <Form.Control
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      onChange={filterByEmail}
                      value={searchEmail}
                      style={{
                        fontSize: 12,
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    ></Form.Control>
                  </InputGroup>
                </Col>
              </Row>
            </td>
          </tr>
        </tbody>
      </Table>
      <Table bordered style={{ fontSize: 12 }}>
        <thead>
          <tr>
            <th>S.NO.</th>
            <th>Full Name</th>
            <th>Contact</th>
            <th>Email</th>
            <th colSpan={2}>Action</th>
          </tr>
        </thead>
        <tbody>
          {data ? (
            foundValue &&
            foundValue
              .slice()
              .reverse()
              .map((user, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{user.fullName}</td>
                  <td>{user.contact}</td>
                  <td>{user.email}</td>
                  <td>
                    <FaEye
                      onClick={() => handleShow(user._id)}
                      style={{ cursor: "pointer" }}
                      size={22}
                      color="blue"
                    />
                  </td>
                  <td>
                    <MdDelete
                      onClick={() => handleDelete(user.id)}
                      style={{ cursor: "pointer" }}
                      size={22}
                      color="red"
                    />
                  </td>
                </tr>
              ))
          ) : (
            <div
              style={{
                width: "100%",
                position: "absolute",
              }}
              className="d-flex justify-content-center align-items-center mt-4"
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            User Details{" "}
            {selectedUserDetails?.payments?.length ? (
              <span className="bg-success badge">Member</span>
            ) : (
              <span className="bg-danger  badge">Not A Member</span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-6">
              <h6
                style={{ background: "#E74C3C" }}
                className="fw-bold text-center m-0 text-white py-2"
              >
                Full Name of User
              </h6>
              <p className="m-0 mt-3 text-center">
                {selectedUserDetails.fullName}
              </p>
            </div>
            <div className="col-6">
              <h6
                style={{ background: "#E74C3C" }}
                className="fw-bold text-center m-0 text-white py-2"
              >
                Contact Of User
              </h6>
              <p className="m-0 mt-3 text-center">
                {selectedUserDetails.contact}
              </p>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-6">
              <h6
                style={{ background: "#E74C3C" }}
                className="fw-bold text-center m-0 text-white py-2"
              >
                Email Of User
              </h6>
              <p className="m-0 mt-3 text-center">
                {selectedUserDetails.email}
              </p>
            </div>
            <div className="col-6">
              <h6
                style={{ background: "#E74C3C" }}
                className="fw-bold text-center m-0 text-white py-2"
              >
                City Of User
              </h6>
              <p className="m-0 mt-3 text-center">{selectedUserDetails.city}</p>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-6">
              <h6
                style={{ background: "#E74C3C" }}
                className="fw-bold text-center m-0 text-white py-2"
              >
                State Of User
              </h6>
              <p className="m-0 mt-3 text-center">
                {selectedUserDetails.state}
              </p>
            </div>
            <div className="col-6">
              <h6
                style={{ background: "#E74C3C" }}
                className="fw-bold text-center m-0 text-white py-2"
              >
                Address Of User
              </h6>
              <p className="m-0 mt-3 text-center">
                {selectedUserDetails.addressLine1}
              </p>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-6">
              <h6
                style={{ background: "#E74C3C" }}
                className="fw-bold text-center m-0 text-white py-2"
              >
                Blood Group Of User
              </h6>
              <p className="m-0 mt-3 text-center">
                {selectedUserDetails.bloodGroup}
              </p>
            </div>
            <div className="col-6">
              <h6
                style={{ background: "#E74C3C" }}
                className="fw-bold text-center m-0 text-white py-2"
              >
                Occupation Of User
              </h6>
              <p className="m-0 mt-3 text-center">
                {selectedUserDetails.occupation}
              </p>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-6">
              <h6
                style={{ background: "#E74C3C" }}
                className="fw-bold text-center m-0 text-white py-2"
              >
                Pincode Of User
              </h6>
              <p className="m-0 mt-3 text-center">
                {selectedUserDetails.pincode}
              </p>
            </div>
            <div className="col-6 position-relative">
              <h6
                style={{ background: "#E74C3C" }}
                className="fw-bold text-center m-0 text-white py-2"
              >
                Attached Id Proof 1
              </h6>
              <div
                className="mt-3 position-relative"
                style={{ height: 100, width: 100 }}
              >
                <img
                  src={`https://cro-org-bucket.s3.ap-south-1.amazonaws.com/${
                    selectedUserDetails &&
                    selectedUserDetails.attachIdProofImage
                  }`}
                  alt=""
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
                <div
                  style={{
                    height: 25,
                    width: 25,
                    background: "white",
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer",
                  }}
                  className="d-flex justify-content-center align-items-center"
                  onClick={() =>
                    window.open(
                      `https://cro-org-bucket.s3.ap-south-1.amazonaws.com/${
                        selectedUserDetails &&
                        selectedUserDetails.attachIdProofImage
                      }`,
                      "_blank"
                    )
                  }
                >
                  <FaDownload />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-6 position-relative">
              <h6
                style={{ background: "#E74C3C" }}
                className="fw-bold text-center m-0 text-white py-2"
              >
                Attached Id Proof 2
              </h6>
              <div
                className="mt-3 position-relative"
                style={{ height: 100, width: 100 }}
              >
                <img
                  src={`https://cro-org-bucket.s3.ap-south-1.amazonaws.com/${
                    selectedUserDetails &&
                    selectedUserDetails.attachPassportImage
                  }`}
                  alt=""
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
                <div
                  style={{
                    height: 25,
                    width: 25,
                    background: "white",
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer",
                  }}
                  className="d-flex justify-content-center align-items-center"
                  onClick={() =>
                    window.open(
                      `https://cro-org-bucket.s3.ap-south-1.amazonaws.com/${
                        selectedUserDetails &&
                        selectedUserDetails.attachPassportImage
                      }`,
                      "_blank"
                    )
                  }
                >
                  <FaDownload />
                </div>
              </div>
            </div>
            {selectedUserDetails?.payments?.length ? (
              <Table className="mt-3">
                <thead className="border border-top border-dark mt-3">
                  <tr>
                    <th>S NO.</th>
                    <th>Order ID</th>
                    <th>Amount</th>
                    <th>Membership Status</th>
                    {/* <th>Paid Amount</th>
                    <th>Due Amount</th> */}
                    <th>On Date</th>
                    <th>Expire Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedUserDetails.payments.map((payment, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{payment?.orderId}</td>
                      <td>{payment?.amount / 100}</td>
                      <td>
                        {payment?.currentStatus === "Active" &&
                          payment?.status === "success" && (
                            <Badge bg="success">Active</Badge>
                          )}

                        {payment?.currentStatus === "Inactive" &&
                          payment?.status === "success" && (
                            <Badge bg="secondary">Expired</Badge>
                          )}

                        {payment?.currentStatus === "Inactive" &&
                          payment?.status !== "success" && (
                            <Badge bg="danger">Payment Failed</Badge>
                          )}
                      </td>

                      {/* <td>{payment?.amount_paid / 100}</td>
                      <td>{payment?.amount_due / 100}</td> */}
                      <td>
                        <Moment format="DD/MM/YYYY">
                          {parseInt(payment?.created_at)}
                        </Moment>
                      </td>
                      <td>
                        <Moment format="DD/MM/YYYY">
                          {parseInt(payment?.expire_at)}
                        </Moment>
                      </td>
                      <td>
                        {/* {payment?.status} */}
                        {payment?.status === "success" ? "success" : "failed"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <h3>No payments are made so far</h3>
            )}
            {/* <div className="col-6 position-relative">
              <h6
                style={{ background: "#E74C3C" }}
                className="fw-bold text-center m-0 text-white py-2"
              >
                Attached Id Proof 3
              </h6>
              <div
                className="mt-3 position-relative"
                style={{ height: 100, width: 100 }}
              >
                <img
                  src={`https://cro-org-bucket.s3.ap-south-1.amazonaws.com/${selectedUserDetails && selectedUserDetails.idProof}`}
                  alt=""
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
                <div
                  style={{
                    height: 25,
                    width: 25,
                    background: "white",
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer",
                  }}
                  className="d-flex justify-content-center align-items-center"
                  onClick={() =>
                    window.open(`https://cro-org-bucket.s3.ap-south-1.amazonaws.com/${selectedUserDetails && selectedUserDetails.idProof}`,
                     "_blank")
                  }
                >
                  <FaDownload />
                </div>
              </div>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MembershipUser;
