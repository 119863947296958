import React, { useEffect } from "react";
import Navbar from "../navbar/Navbar";
import Hero from "../hero/Hero";
import Welcome from "../welcome/Welcome";
import WoWeAre from "../woWeAre/WoWeAre";
import WhatWeDo from "../whatWeDo/WhatWeDo";
import WorkingFor from "../workingFor/WorkingFor";
import Complaint from "../complaint/Complaint";
import Feedback from "../feedback/Feedback";
import Footer from "../footer/Footer";
import YouTube from "../youtube";
import Marqee from "../Marqee/Marqee";

const HomaPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      {/* <Marqee /> */}
      <Hero />
      <Welcome />
      <WoWeAre />
      <WhatWeDo />
      <WorkingFor />
      <Complaint />
      <YouTube />
      <Feedback />
      <Footer />
    </>
  );
};

export default HomaPage;
