import React, { useEffect, useState } from "react";
import Navbar from "../navbar";
import { Container, Card, Button } from "react-bootstrap";

import NoSubs from "../../../assets/img/beam-warning-sign.png";
import { GET_USER_BY_ID } from "../../../graphql/Query";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_RAZORPAY_ORDER,
  CREATE_RZ_ORDER,
  UPDATE_USER_PAYMENTS,
} from "../../../graphql/Mutation";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";

function GetMembership() {
  let keyId = process.env.REACT_APP_RZ_PAY_KEY;
  const [order, setOrder] = useState(null);
  const alert = useAlert();

  const navigate = useNavigate();

  useEffect(() => {
    let bId = sessionStorage.getItem("browser-id");
    if (!bId) {
      navigate("/");
    }
  }, []);

  const [createRzOrder] = useMutation(CREATE_RAZORPAY_ORDER, {
    onError: (e) => {
      alert.error("Error During creating order.");
    },
  });

  const [createRZNewOrder] = useMutation(CREATE_RZ_ORDER, {
    onError: (e) => {
      console.log('my error -1 ',e);
      alert.error(e.message);
    },
  });

  const [updateUserPayments] = useMutation(UPDATE_USER_PAYMENTS, {
    onError: (e) => {
      alert.error(e.message);
    },
  });

  useEffect(() => {
    document.title = "CRO | Get Membership";
  }, []);

  const [user, setUser] = useState(null);

  const { data, loading, error } = useQuery(GET_USER_BY_ID, {
    variables: {
      userId: `${sessionStorage.getItem("browser-id")}`,
    },
  });

  useEffect(() => {
    if (data) {
      setUser(data.getUserById);
    }
  }, [data]);


  const handleSubscriptionClick = () => {
    if (!user) {
      alert.error('user not found.');
      return;
    }
    createRzOrder({
      variables: {
        amount: 1000 * 100,
        currency: "INR",
        receipt: `${user.email}`,
      },
    })
      .then((res) => {
        let {
          id,
          amount,
          status,
          currency,
          amount_due,
          amount_paid,
          attempts,
          notes,
          offer_id,
          receipt,
          created_at,
        } = res.data.createOrder;
        setOrder(res.data.createOrder);
        createRZNewOrder({
          variables: {
            orderInput: {
              amount: amount,
              amount_due: amount_due,
              amount_paid: amount_paid,
              attempts: attempts,
              currency: `${currency}`,
              entity: "Order",
              id: `${id}`,
              notes: [`${notes}`],
              receipt: `${receipt}`,
              status: `${status}`,
              offer_id: `${offer_id}`,
            },
          },
        }).then((res) => {
          updateUserPayments({
            variables: {
              updateUserPaymentsInput: {
                orderId: `${res.data.createRZOrder.id}`,
                userId: `${user._id}`,
              },
            },
          }).then((res) => {
            alert.success("Redirecting  to payment page...");
            let actionButton = document.querySelector(".rz_action_button");
            sessionStorage.removeItem("browser-id");
            actionButton.click();
          });
        });
      })
      .catch((err) => {
        alert.error(err.message);
      });
  };

  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />
      <form
        style={{ display: "none" }}
        method="POST"
        action="https://api.razorpay.com/v1/checkout/embedded"
      >
        <input type="hidden" name="key_id" value={keyId} />
        <input type="hidden" name="amount" value={order ? order.amount : 0} />
        <input type="hidden" name="order_id" value={order ? order.id : ""} />
        <input type="hidden" name="name" value="CRO" />
        <input
          type="hidden"
          name="description"
          value="subscription for 1 year"
        />
        <input
          type="hidden"
          name="image"
          value="https://www.thecro.org/static/media/logo.b9d20dc1c662c93a0d41.png"
        />
        <input
          type="hidden"
          name="prefill[name]"
          value={user ? user.name : ""}
        />
        <input
          type="hidden"
          name="prefill[contact]"
          value={user ? user.contact : ""}
        />
        <input
          type="hidden"
          name="prefill[email]"
          value={user ? user.email : ""}
        />
        <input
          type="hidden"
          name="notes[shipping address]"
          value={user ? user.addressLine1 : ""}
        />
        <input
          type="hidden"
          name="callback_url"
          value="https://cro-backend.vercel.app/payment/success"
        />
        {/* <input type="hidden" name="callback_url" value="http://localhost:4000/payment/success" /> */}
        <input
          type="hidden"
          name="cancel_url"
          value="https://cro-backend.vercel.app/payment/fail"
        />
        {/* <input type="hidden" name="cancel_url" value="http://localhost:3000/payment/fail" /> */}
        <button className="rz_action_button">Submit</button>
      </form>
      <Container className="mt-5">
        <Card className="rounded-3" style={{ width: "25rem", margin: "auto" }}>
          <Card.Body className="px-5 py-5 align-items-center justify-content-center d-flex flex-column">
            <img
              width={100}
              height={100}
              className="mb-4"
              src={NoSubs}
              alt="no subscription"
            />
            <Card.Title>No Membership Found</Card.Title>
            <Card.Text className="text-center">
              You don't have an active membership. Please pay and start your
              membership to access the service.
            </Card.Text>
            <Button
              variant="primary"
              className="border-0"
              style={{ backgroundColor: "#FF814F" }}
              onClick={handleSubscriptionClick}
            >
              Start Membership
            </Button>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default GetMembership;
