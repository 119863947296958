import React, { useEffect } from "react";
import styles from "./style.module.css";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import pdf from "../../assets/act.pdf";
import "bootstrap/dist/css/bootstrap.min.css";

const Navbar = () => {
  const clickHandler = () => {
    window.open(pdf);
  };
  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min");
  }, []);

  const userToken = localStorage.getItem("userToken");
  const adminToken = localStorage.getItem("superAdminToken");
  return (
    <>
      <nav className={`navbar navbar-expand-lg shadow  ${styles.custom_nav}`}>
        <div className="container-fluid">
          <div className="d-flex" style={{ alignItems: "center" }}>
            <Link className="navbar-brand" to="/organisation">
              <img
                src={logo}
                className="d-inline-block mb-2"
                height={60}
                width={60}
                alt=""
              />
            </Link>
            <div>
              <h5
                className="me-3 fw-bolder d-none d-md-block"
                style={{ fontSize: "1.2rem", marginBottom: "0", color: "#000" }}
              >
                उपभोक्ता अधिकार संगठन <br />
                <span style={{ fontSize: "1rem" }}>
                  Consumer Rights Organisation
                </span>
              </h5>
              <h5
                className="me-3 logoText fw-bolder"
                style={{ fontSize: "1.11rem" }}
              ></h5>
            </div>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            id="navbarToggler"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse "
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item me-3 ">
                <a
                  style={{ fontSize: 14 }}
                  className="nav-link active"
                  aria-current="page"
                  href="/"
                >
                  HOME
                </a>
              </li>
              <li className="nav-item me-3 dropdown">
                <a
                  style={{
                    fontWeight: 700,
                    color: "#000",
                    fontSize: 14,
                  }}
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  ABOUT US
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/about/aboutcro">
                      About CRO
                    </Link>
                  </li>
                  <li>
                    <Link onClick={clickHandler} className="dropdown-item">
                      About Consumer Act
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/about/aboutconsumer">
                      About Consumer Rights
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item me-3 dropdown">
                <a
                  style={{
                    fontWeight: 700,
                    color: "#000",
                    fontSize: 14,
                  }}
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  MOVEMENT
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      className="dropdown-item"
                      
                      to="https://cro-org-bucket.s3.ap-south-1.amazonaws.com/PEC+report+by+(PEC+REPORT+ON+NEEDS+OF+INDIAN%C2%A0SMOKERS).pdf"
                      target="_blank"
                    >
                       Public engagement campaign <br /> on needs of Indian Smokers
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/movement/billlekedekho"
                    >
                      Bill Leke Dekho
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/movement/educationSys">
                      Education System
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/movement/foodAdult">
                      Food Adulteration Matters
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/movement/nationalInte">
                      National Integration
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item me-3">
                <Link
                  style={{ fontSize: 14 }}
                  className="nav-link active"
                  aria-current="page"
                  to="/committee"
                >
                  COMMITTEE
                </Link>
              </li>
            </ul>

            <Link to="/complaint">
              <button
                className={`btn btn-sm me-3 text-white fw-bold ${styles.home_btns}`}
                style={{fontSize:12}}
              >
                File a Complaint
              </button>
            </Link>

            {adminToken ? (
              <Link to="/complaint/adminPanel/superAdminDashboardView">
                <button
                  className={`btn btn-sm text-white fw-bold ${styles.home_btns}`}
                >
                  Dashboard
                </button>
              </Link>
            ) : userToken ? (
              <Link to="/complaint/userDashboard/">
                <button
                  className={`btn btn-sm text-white fw-bold ${styles.home_btns}`}
                >
                  Profile
                </button>
              </Link>
            ) : (
              <Link to="/complaint/register">
                <button
                  className={`btn btn-sm text-white fw-bold ${styles.home_btns}`}
                  style={{fontSize:12}}
                >
                  Become a Member
                </button>
              </Link>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
