import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Outlet, Link } from "react-router-dom";
import { FaChevronDown, FaBars } from "react-icons/fa";
import {
  AiOutlineLogout,
  AiOutlineUserAdd,
  AiOutlineHome,
} from "react-icons/ai";
import { MdOutlineNoteAlt } from "react-icons/md";
import { BiSolidContact } from "react-icons/bi";
import { TbDeviceAnalytics } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { RiSecurePaymentLine } from "react-icons/ri";

import "../../../assets/css/componentcss/SuperAdminDashboard.css";
import "../../../assets/css/componentcss/AdminDashboard.css";

import { GET_ALL_COMPLAINTS } from "../../../graphql/Query";
import { useQuery } from "@apollo/client";
import { FaUsers } from "react-icons/fa6";

export default function AdminPanel() {
  const { data } = useQuery(GET_ALL_COMPLAINTS);
  console.log(data);

  const navigate = useNavigate();

  useEffect(() => {
    var dropdown = document.querySelectorAll(".dropdown");
    var flag = 1;
    dropdown.forEach((item) => {
      item.addEventListener("click", function () {
        if (flag === 1) {
          item.classList.add("active");
          flag = 0;
        } else {
          item.classList.remove("active");
          flag = 1;
        }
      });
    });
  }, []);

  useEffect(() => {
    var sidebar = document.querySelector(".sidebar");
    var flag2 = 0;
    const menu = document.querySelector(".bx-menu");
    menu.addEventListener("click", function () {
      if (flag2 == 0) {
        sidebar.classList.add("close");
        flag2 = 1;
      } else {
        sidebar.classList.remove("close");
        flag2 = 0;
      }
    });
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("superAdminToken")) {
      navigate("/complaint/adminLogin");
    }
  }, [navigate]);

  function logoutHandel() {
    localStorage.removeItem("superAdminToken");
    localStorage.removeItem("superAdminId");
    navigate("/complaint");
  }

  return (
    <>
      <div className={"sidebar"}>
        <Link
          to="/complaint"
          className="logo-box"
          style={{ textDecoration: "none" }}
        >
          <div className="logo-name">
            <h6
              style={{
                color: "#fff",
                fontFamily: "poppins",
                fontWeight: "bold",
                fontSize: 25,
                textAlign: "center",
                marginTop: 30,
              }}
            >
              Super Admin Dashboard
            </h6>
          </div>
        </Link>
        <ul className="sidebar-list">
          <li>
            <div className="title">
              <Link
                to="/complaint"
                className="link"
                style={{ textDecoration: "none" }}
              >
                <i className="bx bx-grid-alt">
                  <AiOutlineHome />
                </i>
                <span className="name">Home</span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/complaint"
                className="link submenu-title"
                style={{ textDecoration: "none" }}
              >
                Home
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link
                to="/complaint/adminPanel/superAdminDashboardView"
                className="link"
                style={{ textDecoration: "none" }}
              >
                <i className="bx bx-grid-alt">
                  <TbDeviceAnalytics />
                </i>
                <span className="name">Dashboard</span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/complaint/adminPanel/superAdminDashboardView"
                className="link submenu-title"
                style={{ textDecoration: "none" }}
              >
                Dashboard
              </Link>
            </div>
          </li>

          <li className="dropdown" style={{ overflow: "hidden" }}>
            <div className="title">
              <Link className="link" style={{ textDecoration: "none" }}>
                <i className="bx bx-book-alt">
                  <MdOutlineNoteAlt />
                </i>
                <span className="name">Complaints</span>
              </Link>
              <i className="bx bxs-chevron-down">
                <FaChevronDown />
              </i>
            </div>
            <div className="submenu">
              <Link
                to="/complaint/adminPanel/pendingComplaints"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Pending complaints
              </Link>
              <Link
                to="/complaint/adminPanel/acceptedComplaints"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Accepted complaints
              </Link>
              <Link
                to="/complaint/adminPanel/declinedComplaints"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Declined complaints
              </Link>
              {/* <Link
                to="/complaint/adminPanel/paidComplaints"
                className="link"
                style={{ textDecoration: 'none' }}
              >
                Paid complaints
              </Link>
              <Link
                to="/complaint/adminPanel/unpaidComplaints"
                className="link"
                style={{ textDecoration: 'none' }}
              >
                Unpaid complaints
              </Link> */}
              <Link
                to="/complaint/adminPanel/resolvedComplaints"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Resolved complaints
              </Link>
              <Link
                to="/complaint/adminPanel/all-Complaints"
                className="link"
                style={{ textDecoration: "none" }}
              >
                All complaints
              </Link>
            </div>
          </li>

          <li className="dropdown">
            <div className="title">
              <Link className="link" style={{ textDecoration: "none" }}>
                <i className="bx bx-book-alt">
                  <AiOutlineUserAdd />
                </i>
                <span className="name">Users</span>
              </Link>
              <i className="bx bxs-chevron-down">
                <FaChevronDown />
              </i>
            </div>
            <div className="submenu">
              <Link
                to="/complaint/adminPanel/membershipUser"
                className="link"
                style={{ textDecoration: "none" }}
              >
                All Users
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link
                to="/complaint/adminPanel/contact-details"
                className="link"
                style={{ textDecoration: "none" }}
              >
                <i className="bx bx-grid-alt">
                  <BiSolidContact />
                </i>
                <span className="name">Contact requests</span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/complaint/adminPanel/contact-details"
                className="link submenu-title"
                style={{ textDecoration: "none" }}
              >
                Contact requests
              </Link>
            </div>
          </li>

          {/* <li>
            <div className="title">
              <Link
                to="/complaint/adminPanel/event-reg"
                className="link"
                style={{ textDecoration: 'none' }}
              >
                <i className="bx bx-grid-alt">
                  <FaUsers />
                </i>
                <span className="name">Event Reg.</span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/complaint/adminPanel/contact-details"
                className="link submenu-title"
                style={{ textDecoration: 'none' }}
              >
                Contact requests
              </Link>
            </div>
          </li> */}

          <li className="dropdown">
            <div className="title">
              <Link className="link" style={{ textDecoration: "none" }}>
                <i className="bx bx-book-alt">
                  <RiSecurePaymentLine />
                </i>
                <span className="name">payments</span>
              </Link>
              <i className="bx bxs-chevron-down">
                <FaChevronDown />
              </i>
            </div>
            <div className="submenu">
              <Link
                to="/complaint/adminPanel/payments/success"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Success
              </Link>
              <Link
                to="/complaint/adminPanel/payments/failed"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Failed
              </Link>
              <Link
                to="/complaint/adminPanel/payments/all"
                className="link"
                style={{ textDecoration: "none" }}
              >
                All Transaction
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link
                to="/organisation"
                className="link"
                style={{ textDecoration: "none" }}
                onClick={() => logoutHandel()}
              >
                <i className="bx bx-grid-alt">
                  <AiOutlineLogout />
                </i>
                <span className="name">Log out</span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/organisation"
                className="link submenu-title"
                style={{ textDecoration: "none" }}
                onClick={() => logoutHandel()}
              >
                Log out
              </Link>
            </div>
          </li>
        </ul>
      </div>
      <section className="home">
        <div className="toggle-sidebar">
          <FaBars
            className={"bx bx-menu"}
            style={{
              width: 30,
              height: 30,
              marginLeft: 10,
              marginTop: "-100px",
              color: "#FD7D72",
            }}
          />
        </div>
        <Container style={{ padding: "20px" }} fluid>
          <Row>
            <Col md={12}>
              <Outlet />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
