import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Table, Row, Col, Pagination, Badge } from "react-bootstrap";
import Moment from "react-moment";
import {
  GET_ALL_TRANSACTIONS,
  GET_USER_BY_EMAIL,
} from "../../../../graphql/Query";
import { BsEye } from "react-icons/bs";
import UserDetailModal from "./UserDetailModal";

function AllTransaction() {
  const { loading, data, error } = useQuery(GET_ALL_TRANSACTIONS);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const [getUserByEmail] = useLazyQuery(GET_USER_BY_EMAIL);

  const [showUserDetail, setShowUserDetail] = useState(false);

  const [userDetail, setUserDetails] = useState({
    fullName: "",
    contact: "",
    email: "",
    status: "",
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // Filter function
  const filteredData =
    data &&
    data.getAllOrder.filter((checkout) => {
      return (
        checkout.orderId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        checkout.receipt?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (checkout.amount / 100).toString().includes(searchTerm.toLowerCase())
      );
    });

  // Pagination
  const totalPages = Math.ceil((filteredData?.length || 0) / itemsPerPage);

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  let startPage = Math.max(1, currentPage - 1);
  let endPage = Math.min(startPage + 2, totalPages);

  if (endPage - startPage < 2) {
    startPage = Math.max(1, endPage - 2);
  }

  const pageNumbers = [...Array(endPage - startPage + 1).keys()].map(
    (i) => startPage + i
  );

  const SearchVal = (val) => {
    setSearchTerm(val);
    handleClick(1);
  };

  const handleUserDetail = (email) => {
    console.log("Adsf0", email);
    getUserByEmail({
      variables: {
        email: email,
      },
    }).then((res) => {
      setUserDetails(res?.data?.getUserByEmail);
      setShowUserDetail(true);
    });
  };

  return (
    <div>
      <UserDetailModal
        show={showUserDetail}
        setShow={setShowUserDetail}
        Data={userDetail}
      />
      <Row className="mb-3">
        <Col md={9}></Col>
        <Col md={3}>
          <input
            type="text"
            className="shadow border rounded-2"
            placeholder="Search by Order ID, Email, or Amount"
            value={searchTerm}
            style={{ width: "100%" }}
            onChange={(e) => SearchVal(e.target.value)}
          />
        </Col>
      </Row>

      {filteredData && filteredData.length > 0 && (
        <div>
          <Table bordered responsive style={{ fontSize: 12 }}>
            <thead>
              <tr>
                <th>S.NO.</th>
                <th>Order Id</th>
                <th>Email</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Date</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {filteredData
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((checkout, index) => (
                  <tr key={checkout.id}>
                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                    <td>{checkout.orderId}</td>
                    <td>{checkout.receipt}</td>
                    <td>
                      {checkout.amount / 100} {checkout.currency}
                    </td>
                    <td>
                      {checkout && (
                        <>
                          {checkout.status === "failed" && (
                            <Badge bg="danger" className="badge badge-danger">
                              Failed
                            </Badge>
                          )}

                          {checkout.status === "success" && (
                            <Badge bg="success" className="badge badge-success">
                              Success
                            </Badge>
                          )}

                          {(checkout.status === "null" ||
                            checkout.status === "" ||
                            checkout.status === null ||
                            checkout.status === undefined) && (
                            <Badge bg="danger" className="badge badge-danger">
                              Failed
                            </Badge>
                          )}
                        </>
                      )}
                      {/* {checkout.status !== null && checkout.status !== '' && checkout.status !== 'null' ? checkout.status : 'failed'} */}
                    </td>
                    <td>
                      <Moment format="DD MMM YYYY">{checkout.createdAt}</Moment>
                    </td>
                    <td>
                      <BsEye
                        style={{
                          background: "#C1C7CC",
                          minWidth: "2rem",
                          minHeight: "2rem",
                          padding: "0.5rem",
                          borderRadius: "0.4rem",
                          font: "black",
                        }}
                        onClick={() => handleUserDetail(checkout.receipt)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          <div className="d-flex justify-content-center">
            <Pagination>
              <Pagination.Prev
                onClick={() => handleClick(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {pageNumbers.map((page, index) => (
                <Pagination.Item
                  key={index}
                  active={page === currentPage}
                  onClick={() => handleClick(page)}
                >
                  {page}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => handleClick(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </div>
        </div>
      )}

      {filteredData && filteredData.length === 0 && <p>No data found.</p>}
    </div>
  );
}

export default AllTransaction;
