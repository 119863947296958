import React from "react";
import { Button, Container, Modal } from "react-bootstrap";

export default function UserDetailsModal({ show, setShow, Data }) {
  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>User Deatils</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Data !== null ? (
          <Container>
            <p>
              <b>Name:</b> {Data?.fullName}
            </p>
            <p>
              <b>Email:</b> {Data?.email}
            </p>
            <p>
              <b>Contact:</b> {Data?.contact}
            </p>
            <p>
              <b>Status:</b>{" "}
              {Data?.payments?.length ? (
                <span className="text-success">Member</span>
              ) : (
                <span className="text-danger">Not A Member</span>
              )}
            </p>
          </Container>
        ) : (
          <h3>No user found with this records</h3>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
