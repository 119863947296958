import React from 'react'
import Navbar from './navbar'
import Lottie from 'react-lottie';
import * as animationData from '../../assets/img/anim/about2.json'
import peoples from '../../assets/img/icons/peoples.png'
import Footer from './footer';
import {useNavigate} from "react-router-dom"

const Membership = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const navigate = useNavigate();
    const back = ()=>{
        navigate("/")
    }
    const forward = ()=>{
        navigate("/register")
    }

    return (
        <>
            <Navbar />
            <div className="container-fluid mt-9 pe-5 ps-5" >
                <h1 className='text-center mb-5' style={{ fontWeight: "700", color: "#000" }}>Conditions and agreement</h1>
                <p className='mb-3'>To <br />The President CONSUMER RIGHTS ORGANISATION</p>
                <p className='mb-3'>Sir, <br />I want to take the Membership of the Consumer Rights Organisation for which I am submitting the Membership fee of Rs 200/- (rupees Two Hundred Only). Therefore, kindly register me as a Member of the Organization.</p>
                <p className='mb-3'>I hereby declare that I am ready to abide by the rules and sub rules of the Consumer Rights Organisation in their Present / Current from or when they are updated from time to time.</p>
                <p className='mb-3'>I promise to be loyal towards the mission of the organisation and would follow all those rules and regulations which are given to me by organisation to fulfill its mission.</p>
                <div className='text center mt-5' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <button onClick={back} btn className='m-4' style={{ padding: ".4rem 2.1rem", background: "transparent", border: "2px solid #FF937D", color: "#FF937D", fontSize: "1.1rem" }}>Back</button>
                    <button onClick={forward} btn style={{ padding: ".4rem 2.1rem", background: "#ff5f3f", border: "2px solid #ff5f3f", color: "#fff", fontSize: "1.1rem" }}>Agree</button>
                </div>
            </div>

            <div className="container mt-7">
                <h1 className='text-center mb-7' style={{ fontWeight: "700", color: "#000" }}>Some terms and conditions</h1>
                <div className="row">
                    <div className="col-md-6">
                        <h5 className='mb-4' style={{fontWeight: "600", fontSize: "1.4rem" }}>To become member of CRO India you have <br /> following ability</h5>
                        <ol className='p-0'>
                            <li style={{ marginBottom: "6px", fontSize: ".9rem" }}>1. Person should not be less than 18 years of age.</li>
                            <li style={{ marginBottom: "6px", fontSize: ".9rem" }}>2. Person should be a citizen of India.</li>
                            <li style={{ marginBottom: "6px", fontSize: ".9rem" }}>3. Person should pledge to follow the rules of the organization.</li>
                            <li style={{ marginBottom: "6px", fontSize: ".9rem" }}>4. Person should be of Good character.</li>
                        </ol>
                    </div>
                    <div className="col-md-6 text-center">
                    <Lottie style={{marginTop:"-50px"}} options={defaultOptions} height={300} width={300} />
                    </div>
                </div>
                <div className="row mt-7">
                <div className="col-md-6 text-center">
                    <img src= {peoples} alt="" height={230} style={{marginTop:"-30px"}}  />
                    </div>
                    <div className="col-md-6">
                        <h5 className='mb-4' style={{fontWeight: "600", fontSize: "1.4rem" }}>By becoming a member of CRO India, you are <br /> entitled to</h5>
                        <ol className='p-0'>
                            <li style={{ marginBottom: "6px", fontSize: ".9rem" }}>1.  You are able to work freely.</li>
                            <li style={{ marginBottom: "6px", fontSize: ".9rem" }}>2. Handling of your complaints without charges (free)</li>
                            <li style={{ marginBottom: "6px", fontSize: ".9rem" }}>3. An Identity card for Life Members on production of stamp size photo.</li>
                            <li style={{ marginBottom: "6px", fontSize: ".9rem" }}>4. Free Guidance and advice.</li>
                        </ol>
                    </div>
                    
                </div>
            </div>

            <Footer/>
        </>
    )
}

export default Membership