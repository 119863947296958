import React, { useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { UPDATE_USER_PASSWORD } from '../../../graphql/Mutation'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom';

export default function UserPasswordReset() {
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId')

    const [validated, setValidated] = useState(false);

    const [password, setPassword] = useState('')

    const [updatePassword] = useMutation(UPDATE_USER_PASSWORD)

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setValidated(true);
            event.preventDefault();
            updatePassword({
                variables: {
                    "userId": `${userId}`,
                    "password": `${password}`,
                }
            }).then(() => {
                localStorage.removeItem('userToken')
                localStorage.removeItem('userId')
                navigate('/');
            })
        }
    };

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                        <Form.Label style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>Password</Form.Label>
                        <Form.Control style={{ fontFamily: 'poppins', fontSize: 12 }} required type="text" placeholder="Password" onChange={e => setPassword(e.target.value)} value={password} />
                    </Form.Group>
                </Row>
                <Button type="submit" className="mx-auto d-block" style={{ background: "#FF5F3F", border: "none", marginTop: 20 }}>Change</Button>
            </Form>
        </>
    )
}
