import React, { useCallback, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
  Spinner,
  Badge,
} from "react-bootstrap";
import { FaEye, FaExchangeAlt, FaEdit } from "react-icons/fa";

import {
  GET_PENDING_COMPALINT,
  GET_ACCEPTED_COMPALINT,
  GET_DECLINED_COMPALINT,
  GET_PAID_COMPALINT,
  GET_UNPAID_COMPALINT,
  GET_RESOLVED_COMPALINT,
  GET_USER_BY_ID,
} from "../../../../graphql/Query";
import { COMPLAINT_STATUS_CHANGE } from "../../../../graphql/Mutation";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import Moment from "react-moment";
import { FaDownload } from "react-icons/fa6";
import UserDetailsModal from "./UserDetailsModal";

export default function DeclinedComplaints() {
  const { data, loading } = useQuery(GET_DECLINED_COMPALINT, {
    pollInterval: 300,
  });

  const [complaintIdState, setComplaintIdState] = useState("");
  const [statusNameState, setStatusNameState] = useState("");
  const [showregionmodal, setShowregionmodal] = useState(true);
  const [region, setRegion] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [changeStatus] = useMutation(COMPLAINT_STATUS_CHANGE, {
    refetchQueries: [
      GET_PENDING_COMPALINT,
      GET_ACCEPTED_COMPALINT,
      GET_DECLINED_COMPALINT,
      GET_PAID_COMPALINT,
      GET_UNPAID_COMPALINT,
      GET_RESOLVED_COMPALINT,
    ],
  });

  function clickShowModal(cId) {
    setComplaintIdState(cId);
    handleShow();
  }

  function changeStatusHandel() {
    changeStatus({
      variables: {
        complaintId: `${complaintIdState}`,
        statusName: `${statusNameState}`,
      },
    }).then(() => {
      handleClose();
      handleClose2();
    });
  }

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedComplaint, setSelectedComplaint] = useState({});

  const clickShowDetailsModal = (cId) => {
    const selectedComplaint = data.getDeclinedComplaint.find(
      (Cdata) => Cdata.id === cId
    );
    setSelectedComplaint(selectedComplaint);
    setShowDetailsModal(true);
    console.log(selectedComplaint);
  };
  const handleClose2 = () => {
    setShowregionmodal(false);
  };

  const [getUserById, { data: userData }] = useLazyQuery(GET_USER_BY_ID);

  const [userDetailData, setUserDetailData] = useState();
  const [showUserDetails, setShowUserDetails] = useState(false);

  const UserDataHandler = useCallback((userId) => {
    getUserById({
      variables: {
        userId: userId,
      },
    }).then((res) => {
      setUserDetailData(res?.data?.getUserById);
      setShowUserDetails(true);
    });
  });
  return (
    <>
      {/* User detail modal --start */}
      {showUserDetails ? (
        <UserDetailsModal
          show={showUserDetails}
          setShow={setShowUserDetails}
          Data={userDetailData}
        />
      ) : null}
      {/* User detail modal --end */}
      <Container>
        <Row>
          <Col>
            <h1
              style={{
                fontFamily: "poppins",
                fontSize: 20,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Declined Complaints
            </h1>
            <Table bordered style={{ fontFamily: "poppins", fontSize: 12 }}>
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Complaint Id</th>
                  <th>Date/Time</th>
                  <th>Type</th>
                  <th>Membership</th>
                  <th>IsUser</th>
                  <th>Status</th>
                  <th colSpan={2}>Action</th>
                </tr>
              </thead>
              <tbody>
                {data ? (
                  data.getDeclinedComplaint
                    .slice()
                    .reverse()
                    .map((Cdata, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{Cdata.complaintId}</td>
                        <td>
                          <Moment format="DD-MM-YYYY, hh:mm A">
                            {Cdata.createdDateTime}
                          </Moment>
                        </td>
                        <td>{Cdata.complaintType}</td>
                        <td>
                          {Cdata.isPremium ? (
                            <Badge bg="success">Membership</Badge>
                          ) : (
                            <Badge bg="danger">No Membership</Badge>
                          )}
                        </td>
                        <td>
                          {Cdata.isExistUser ? (
                            <Badge
                              style={{ cursor: "pointer" }}
                              onClick={() => UserDataHandler(Cdata.userId._id)}
                              bg="success"
                            >
                              User
                            </Badge>
                          ) : (
                            <Badge bg="danger">Not a User</Badge>
                          )}
                        </td>
                        <td>{Cdata.status}</td>
                        {/* <td>
                          <Button
                            size="sm"
                            variant="success"
                            onClick={() => clickShowDetailsModal(Cdata.id)}
                          >
                            <FaEye />
                          </Button>
                        </td> */}
                        <td>
                          <FaEye
                            onClick={() =>
                              clickShowDetailsModal(Cdata.complaintId)
                            }
                            style={{ cursor: "pointer" }}
                            size={22}
                            color="blue"
                          />
                        </td>
                        <td>
                          <FaEdit
                            onClick={() => clickShowModal(Cdata._id)}
                            style={{ cursor: "pointer" }}
                            size={22}
                          />
                        </td>
                        {/* <td>
                          <Button
                            size="sm"
                            variant="primary"
                            className="mx-auto d-block"
                            disabled={typeof Cdata.userId !== "string"}
                            onClick={() => UserDataHandler(Cdata.userId)}
                          >
                            <FaEye />
                          </Button>
                        </td> */}
                        {/* <td>
                          <Button
                            size="sm"
                            variant="primary"
                            onClick={() => clickShowModal(Cdata.id)}
                          >
                            <FaExchangeAlt />
                          </Button>
                        </td> */}
                      </tr>
                    ))
                ) : (
                  <div
                    style={{
                      width: "100%",
                      position: "absolute",
                    }}
                    className="d-flex justify-content-center align-items-center mt-4"
                  >
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                )}

                {data?.getDeclinedComplaint.length === 0 && !loading && (
                  <tr>
                    <td colSpan={9}>No Data Found.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <Form.Control
            required
            as="select"
            style={{ fontFamily: "poppins", fontSize: 12 }}
            onChange={(e) => setStatusNameState(e.target.value)}
            value={statusNameState}
          >
            <option value="" selected="selected" disabled="disabled">
              Choose Status
            </option>
            <option value="pending">Pending</option>
            <option value="accepted">Accepted</option>
            <option value="paid">Paid</option>
            <option value="unpaid">Unpaid</option>
            <option value="resolved">Resolved</option>
          </Form.Control>
          {statusNameState === "declined" ? (
            ""
          ) : (
            <Button
              onClick={() => changeStatusHandel()}
              className="mx-auto d-block"
              style={{ marginTop: 20 }}
            >
              Change
            </Button>
          )}
        </Modal.Body>
      </Modal>
      {statusNameState === "declined" ? (
        <Modal show={showregionmodal}>
          <Modal.Header>
            <Modal.Title>Reasion Declined</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form>
              <Row>
                <Form.Group controlId="firstName">
                  <Form.Control
                    type="text"
                    style={{
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                      width: "100%",
                    }}
                    placeholder="Enter Region of Declined"
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                  />
                </Form.Group>
              </Row>
            </Form>
          </Modal.Body>

          {/* </Form.Group> */}
          {/* <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body> */}
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>

            <Button
              variant="primary"
              onClick={() => changeStatusHandel()}
              className="mx-auto d-block"
              style={{ marginTop: 20 }}
              disabled={region ? false : true}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}
      {/* modal for show the details of the complaint */}
      <Modal
        show={showDetailsModal}
        onHide={() => setShowDetailsModal(false)}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ width: "100%" }}>
          <Col md={12}>
            <Row md={12}>
              <h1
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontFamily: "poppins",
                  fontSize: 25,
                }}
              >
                Complaint Details
              </h1>
              <Table
                bordered
                style={{ fontFamily: "poppins", fontSize: 12 }}
                responsive
              >
                <tbody>
                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      {" "}
                      Complainer Details
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <p>
                        <b>Full Name:</b> {selectedComplaint?.fullName}
                      </p>
                    </td>
                    <td colSpan={3}>
                      <p>
                        <b>Phone Number:</b> {selectedComplaint?.phone_number}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={6}>
                      <p>
                        <b>Email:</b> {selectedComplaint?.email}
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Details of opposite party
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>
                      Company/Firm/Service Provider Name
                    </td>
                    <td>{selectedComplaint.yourProviderName}</td>
                    <td style={{ fontWeight: "bold" }}>Email</td>
                    <td>{selectedComplaint.yourEmail}</td>
                    <td style={{ fontWeight: "bold" }}>Contact</td>
                    <td>{selectedComplaint.yourContact}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>
                      Name of concern person
                    </td>
                    <td>{selectedComplaint.concernPerson}</td>
                    <td style={{ fontWeight: "bold" }}>Name of product</td>
                    <td>{selectedComplaint.productName}</td>
                    <td style={{ fontWeight: "bold" }}>Address</td>
                    <td>{selectedComplaint.yourFullAddress}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>City</td>
                    <td>{selectedComplaint.yourCity}</td>
                    <td style={{ fontWeight: "bold" }}>State</td>
                    <td>{selectedComplaint.yourState}</td>
                    <td style={{ fontWeight: "bold" }}>Pincode</td>
                    <td>{selectedComplaint.yourPincode}</td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      {" "}
                      Have You Complained To Anyother Platform Earlier
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>
                      Have You Complained To Anyother Platform Earlier
                    </td>
                    <td>{selectedComplaint.haveYouComplaintToAnother}</td>
                    <td style={{ fontWeight: "bold" }}>Name of Platform</td>
                    <td colspan={3}>
                      {selectedComplaint.haveYouComplaintToAnotherText}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Resolution
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Resolution</td>
                    {selectedComplaint.resolution ? (
                      <td>
                        {selectedComplaint &&
                          selectedComplaint.resolution.map((d) => (
                            <h6 style={{ fontFamily: "poppins" }}>{d},</h6>
                          ))}
                      </td>
                    ) : (
                      ""
                    )}
                    <td style={{ fontWeight: "bold" }}>Name of Platform</td>
                    <td colspan={3}>
                      {selectedComplaint.haveYouComplaintToAnotherText}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Product/Service Name
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>
                      {selectedComplaint.haveStartCourtActionText}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Product Or Service Value (INR)
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>₹{selectedComplaint.productValue}</td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      How Would You Like Your Complaint To Get Solved?
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>
                      ₹{selectedComplaint.howYouLikeComplaintSolve}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      When Did This Transaction / Problem Occur?
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Date</td>
                    <td>{selectedComplaint.whenProblemOccurDate}</td>
                    <td style={{ fontWeight: "bold" }}>Time</td>
                    <td colspan={3}>
                      {selectedComplaint.whenProblemOccurTime}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Select A Type Which Is Most Relevant To Your Complaint
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>{selectedComplaint.complaintType}</td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Please Describe Your Complaint In Detail
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>
                      {selectedComplaint.complaintDescription}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Decline Reason
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>{selectedComplaint.complaintReason}</td>
                  </tr>
                  <tr>
                    <td
                      colspan={6}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                    >
                      Attechments
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9} className="d-flex">
                      {selectedComplaint &&
                        selectedComplaint.image &&
                        selectedComplaint.image.length > 0 &&
                        selectedComplaint.image.map((r) => (
                          <div
                            className="mt-3 position-relative ms-3"
                            style={{ height: 100, width: 100 }}
                          >
                            <img
                              src={
                                r
                                  ? `https://cro-org-bucket.s3.ap-south-1.amazonaws.com/${r}`
                                  : "https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg"
                              }
                              alt=""
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                              }}
                            />
                            <div
                              style={{
                                height: 25,
                                width: 25,
                                background: "white",
                                position: "absolute",
                                right: 0,
                                top: 0,
                                cursor: "pointer",
                              }}
                              className="d-flex justify-content-center align-items-center"
                              onClick={() =>
                                window.open(
                                  `https://cro-org-bucket.s3.ap-south-1.amazonaws.com/${
                                    r ? r : ""
                                  }`,
                                  "_blank"
                                )
                              }
                            >
                              <FaDownload />
                            </div>
                          </div>
                        ))}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>
    </>
  );
}
