import React from 'react';
import EditUser from './EditUser';
import UserPasswordReset from './UserPasswordReset';
import { Container, Row, Col } from 'react-bootstrap'

export default function UserSettings() {

  return (
    <Container>
      <Row>
        <Col md={8} style={{ borderRight: '1px solid red' }}>
          <h1 style={{ textAlign: 'center', fontFamily: 'poppins', fontWeight: 'bold', fontSize: 20 }}>Update Profile</h1>
          <EditUser />
        </Col>
        <Col md={4}>
          <h1 style={{ textAlign: 'center', fontFamily: 'poppins', fontWeight: 'bold', fontSize: 20 }}>Reset Password</h1>
          <UserPasswordReset />
        </Col>
      </Row>
    </Container>
  )
}
