import React from "react";

const Welcome = () => {
  return (
    <>
      <div className="container-fluid mt-5 pe-md-5 ps-md-5 text-center">
        <h2 className="mb-3" style={{ color: "#ffa202" }}>
          Welcome to India&#39;s Leading Consumer Rights Organisation - CRO
        </h2>
        <p style={{fontSize:"1rem"}}>
          UPBHOKTA ADHIKAR SANGTHAN (Consumer Rights Organisation) is a
          registered organisation, whose registration number is
          05/23/01/08574/10. The work area of the organisation is whole India.
        </p>
      </div>
    </>
  );
};

export default Welcome;
