import React, { useState, useEffect } from 'react';
import Navbar from '../navbar';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { USER_LOGIN } from '../../../graphql/Mutation';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAlert } from 'react-alert';
import '../../../assets/css/userlogin.css';

export default function UserLoginComplain() {
  const alert = useAlert();

  const navigate = useNavigate();
  const location = useLocation();

  const [validated, setValidated] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userLogin] = useMutation(USER_LOGIN, {
    onCompleted: ({ signInUser }) => {
      localStorage.setItem('userToken', signInUser.userToken);
      localStorage.setItem('userId', signInUser.userId);
      navigate('/complaint');
    },
    onError: (e) => {
      alert.error('Username & Password not match!!!');
    },
  });

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      userLogin({
        variables: {
          signInUserInput: {
            email: `${email}`,
            password: `${password}`,
          },
        },
      }).then(() => {
        setEmail('');
        setPassword('');
      });
    }
  };

  useEffect(() => {
    if (localStorage.getItem('userToken')) {
      navigate('/complaint');
    }
  }, [navigate]);

  return (
    <div className="loginContainer">
      <Navbar />
      <Container style={{ marginTop: 80, padding: 100, marginBottom: '100%' }} fluid>
        <Row>
          <Col md={4}></Col>
          <Col md={4}>
            <Card style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
              <h1 style={{ fontFamily: 'poppins', fontSize: 25, textAlign: 'center', marginTop: 20 }}>LOGIN NOW</h1>
              <Card.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <Form.Label style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>Email</Form.Label>
                      <Form.Control style={{ fontFamily: 'poppins', fontSize: 12 }} required type="text" placeholder="Email" onChange={e => setEmail(e.target.value)} value={email} />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <Form.Label style={{ fontFamily: 'poppins', fontSize: 12, fontWeight: 'bold' }}>Password</Form.Label>
                      <Form.Control style={{ fontFamily: 'poppins', fontSize: 12 }} required type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} value={password} />
                    </Form.Group>
                  </Row>
                  <Button type="submit" className="mx-auto d-block loginBtnn" style={{ width: '80%' }}>Login</Button>
                  <h1 style={{ fontFamily: 'poppins', fontSize: 12, textAlign: 'center', color: '#95a5a6', marginTop: 20 }}>Don't have an account? <Link to="/complaint/register" style={{ color: '#ff5748' }}>Register Now</Link></h1>
                  <h1 style={{ fontFamily: 'poppins', fontSize: 12, textAlign: 'center', fontWeight: 'bold', color: '#95a5a6' }}>Forgot Password?</h1>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}></Col>
        </Row>
      </Container>
    </div>
  );
}
