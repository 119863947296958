import React, { useEffect, useState } from "react";
import "../assets/css/youtube.css";
import { Container, Row, Col } from "react-bootstrap";
import ReactPlayer from "react-player";
import { Timeline } from "react-twitter-widgets";

const YouTube = () => {
  // const [latestVideo, setLatestVideo] = useState(null);

  // useEffect(() => {
  //   async function fetchLatestVideo() {
  //     try {
  //       // Fetch the latest video data from the YouTube Data API
  //       const response = await fetch(
  //         `https://www.googleapis.com/youtube/v3/search?key=AIzaSyBdEk2vH-N_s0DLATSaJDvhcOu3s5CaA8I&channelId=UC-1rTDjOF4Ro3gnTZ7-SAjQ&part=snippet,id&order=date&maxResults=1`
  //       );
  //       const data = await response.json();

  //       // Extract the video ID from the response
  //       const videoId = data.items[0].id.videoId;

  //       // Set the latest video ID
  //       setLatestVideo(videoId);
  //     } catch (error) {
  //       console.error("Error fetching latest video:", error);
  //     }
  //   }

  //   fetchLatestVideo();
  // }, []);

  return (
    <>
      <Container
        style={{ marginTop: 30, marginBottom: 70 }}
        fluid
        className="bg-white py-5"
      >
        <Row>
          <Col md={6}>
            <ReactPlayer
              controls={true}
              width="100%"
              height="100%"
              // url={`https://www.youtube.com/watch?v=${latestVideo}`}
              url={`https://www.youtube.com/watch?v=QbV-7RDJtik&ab_channel=CROConsumerRightsOrganisation`}
            />
          </Col>
          <Col md={6}>
            <Timeline
              dataSource={{ sourceType: "profile", screenName: "thecroindia" }}
              options={{ width: "100%", height: 410 }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default YouTube;
