/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import {
  FaFacebook,
  FaTwitterSquare,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  // const clickName  = location;
  // console.log("clickName",location);

  useEffect(() => {
    if (location.state !== null) {
      if (location && location.state.clickName === "team") {
        scrollToTeamSection();
      }
    }
  }, [location]);

  const scrollToTeamSection = () => {
    scroller.scrollTo("team-section", {
      smooth: true,
      offset: -50,
      duration: 50,
    });
  };
  const scrollToAboutSection = () => {
    scroller.scrollTo("about-section", {
      smooth: true,
      offset: -50,
      duration: 50,
    });
  };
  return (
    <>
      <Container fluid style={{ background: "#e74c3c" }}>
        <hr className="text-200" />
        <div className="row justify-content-lg-between circle-blend-right circle-danger">
          <div className="col-6 col-sm-4 col-lg-auto mb-3">
            <h6 className="my-4 fw-bold fs-0" style={{ color: "#fff" }}>
              Quick Links
            </h6>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="mb-2">
                <Link
                  className="text-decoration-none"
                  to="/complaint/about"
                  style={{ color: "#fff" }}
                  onClick={scrollToTeamSection}
                  state={{ clickName: "team" }}
                >
                  Our Team
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  className="text-decoration-none"
                  to="/complaint/about"
                  style={{ color: "#fff" }}
                  onClick={scrollToAboutSection}
                  state={{ clickName: "about" }}
                >
                  About Us
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  className="text-decoration-none"
                  to="/complaint/contact"
                  style={{ color: "#fff" }}
                >
                  Contact Us
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  className="text-decoration-none"
                  to="/complaint/faq"
                  style={{ color: "#fff" }}
                >
                  FAQs
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-sm-4 col-lg-auto mb-3">
            <h6 className="my-4 fw-bold fs-0" style={{ color: "#fff" }}>
              Legal
            </h6>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="mb-2">
                <Link
                  className="text-decoration-none"
                  to="/complaint/terms-condition"
                  style={{ color: "#fff" }}
                >
                  Terms & Conditions
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  className="text-decoration-none"
                  to="/complaint/privacy-policy"
                  style={{ color: "#fff" }}
                >
                  Privacy Policy
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  className="text-decoration-none"
                  to="/complaint/refund-policy"
                  style={{ color: "#fff" }}
                >
                  Refund Policy
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  className="text-decoration-none"
                  to="/complaint/cancellation-policy"
                  style={{ color: "#fff" }}
                >
                  Cancellation Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-sm-4 col-lg-auto mb-3">
            <h6 className="my-4 fw-bold fs-0" style={{ color: "#fff" }}>
              Important Links
            </h6>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="mb-2">
                <a
                  className="text-decoration-none"
                  target="_blank"
                  href="https://www.india.gov.in"
                  style={{ color: "#fff" }}
                >
                  https://www.india.gov.in
                </a>
              </li>
              <li className="mb-2">
                <a
                  className="text-decoration-none"
                  target="_blank"
                  href="https://www.mygov.in"
                  style={{ color: "#fff" }}
                >
                  https://www.mygov.in
                </a>
              </li>
              <li className="mb-2">
                <a
                  className="text-decoration-none"
                  target="_blank"
                  href="https://ncdrc.nic.in"
                  style={{ color: "#fff" }}
                >
                  https://ncdrc.nic.in
                </a>
              </li>
              <li className="mb-2">
                <a
                  className="text-decoration-none"
                  target="_blank"
                  href="https://consumeraffairs.nic.in"
                  style={{ color: "#fff" }}
                >
                  https://consumeraffairs.nic.in
                </a>
              </li>
              <li className="mb-2">
                <a
                  className="text-decoration-none"
                  target="_blank"
                  href="https://consumerhelpline.gov.in"
                  style={{ color: "#fff" }}
                >
                  https://consumerhelpline.gov.in
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-sm-4 col-lg-auto mb-3">
            <h6 className="my-4 fw-bold fs-0" style={{ color: "#fff" }}>
              Complaint
            </h6>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="mb-2">
                <Link
                  className="text-decoration-none"
                  to="/complaint"
                  style={{ color: "#fff" }}
                >
                  File a Complaint
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-sm-4 col-lg-auto mb-3">
            <h6 className="my-4 fw-bold fs-0" style={{ color: "#fff" }}>
              Membership
            </h6>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="mb-2">
                <Link
                  className="text-decoration-none"
                  to="/complaint/register"
                  style={{ color: "#fff" }}
                >
                  Become a member
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-sm-4 col-lg-auto mb-3">
            <h6 className="my-4 fw-bold fs-0" style={{ color: "#fff" }}>
              FOLLOW
            </h6>
            <ul className="list-unstyled list-inline my-3">
              <li className="list-inline-item me-3">
                <a className="text-decoration-none" href="#!">
                  <FaFacebook size={25} color="#fff" />
                </a>
              </li>
              <li className="list-inline-item me-3">
                <a className="text-decoration-none" href="#!">
                  <FaTwitterSquare size={25} color="#fff" />
                </a>
              </li>
              <li className="list-inline-item me-3">
                <a className="text-decoration-none" href="#!">
                  <FaInstagram size={25} color="#fff" />
                </a>
              </li>
              <li className="list-inline-item">
                <a className="text-decoration-none" href="#!">
                  <FaYoutube size={27} color="#fff" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr className="text-200 mb-0" />
        <div className="row justify-content-md-between justify-content-evenly py-3">
          <div className="col-12 col-sm-8 col-md-6 col-lg-auto text-center text-md-start">
            <p className="fs-0 my-2" style={{ color: "#fff" }}>
              All rights Reserved{" "}
              <span className="fw-bold" style={{ color: "#fff" }}>
                &copy; thecro.org, 2023
              </span>
            </p>
          </div>
          <div className="col-12 col-sm-8 col-md-6">
            <p className="text-center text-md-end" style={{ color: "#fff" }}>
              {" "}
              Developed and manage by
              <a
                className="fw-bold"
                style={{ color: "#fff" }}
                href="https://softseekersinfotech.com/"
                target="_blank"
              >
                {" "}
                Softseekers Infotech Private Limited
              </a>
            </p>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Footer;
